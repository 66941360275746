import React, { useEffect } from 'react'

import './style.scss'

const NATContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>U.S. Citizenship Through Naturalization</h1>
            <p>Naturalization is the process by which people who were not born U.S. citizens can obtain citizenship.</p>

            <p>The applicant must obtain a green card (i.e. permanent residency in the United States) first and must have the requisite amount of time in the U.S. (normally continuous residence for 30 months).</p>

            <h2>U.S. Citizenship Through Naturalization Requirements</h2>
            <ol>
                <li><strong>Good moral character</strong>: You have not been arrested for felonies or misdemeanors that carry a possible penalty of over one year's prison.</li>
                <li><strong>Military registration</strong>: You must register as being available to serve in the U.S. military unless you are exempted due to age or other reasons.</li>
                <li><strong>Oath of Allegiance</strong>: You must take the Oath of Allegiance.</li>
                <li><strong>Understanding of U.S. Civics</strong>: You must be able to demonstrate a good understanding about the way the United States operates and how Americans live.</li>
            </ol>

            <blockquote>Naturalization applications are often straightforward. We <strong>strongly</strong> recommend you arrange a free strategy session with one of our immigration consultants as soon as you consider applying.</blockquote>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2XBZBRGbRPawBAnBrVFjbZjJmI23AAKv7a0AIgrHfyRaQ0gLoATl9dSPhMBigEHx9"></div>
        </div>
    </div>
    )
}
export default NATContentArea