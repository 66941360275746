import React, { useEffect } from 'react'

import './style.scss'

const K2ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>K-2 Visa</h1>
            <p>The K-2 Children Visa is for a child or children of a <a href='https://onlinevisas.com/k-visas/'>K-1 </a> (Fiancé) Visa holder.</p>

            <h2>K-2 Child Visa Qualification Criteria</h2>
            <p>K-2 Child Visa qualification criteria include:</p>
            <ol>
                <li>Must be under the age of 21.</li>
                <li>And must be an unmarried child of a K-1 applicant.</li>
            </ol>

            <h2>K-2 Visa Additional Requirements and Terms</h2>
            <p>Not required to enter with the primary K-1 visa holder - the K-2 holder may enter after the K-1 holder enters as long as the K-1 visa remains valid. The K-2 child visa holder may not enter before the K-1 visa holder.</p>

            <h3>Employment</h3>
            <p>May apply for a work permit from the USCIS by way of an Employment Authorization Document (EAD). You cannot begin work until the EAD has been granted.</p>

            <h3>Study</h3>
            <p>The applicant may study under a K-2 visa.</p>

            <h3>Length of Stay</h3>
            <p>A K-2 visa is valid as long as the primary K-1 visa is valid. If the marriage does not occur within 90 days of entry, the K-2 holder must depart the U.S. No extensions are available.</p>

            <h3>Change of Status / Green Card</h3>
            <p>The child may apply for an Adjustment of Status to permanent residency after the marriage takes place.</p>

            <h3>Citizenship</h3>
            <p>If you came to the U.S. on a K-2 visa, you may apply for citizenship 5 years after your Adjustment of Status is approved. However, depending on your age when your K-1 parent married & became a U.S. citizen, you may be granted citizenship automatically.</p>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/31jwXP5evbWNTa5Hla4SRadgXvq93cDhdN7F2FuFKUtDEhOrL6LAykeh1jCaEUy9Z"></div>
            </div>
    </div>
    )
}
export default K2ContentArea