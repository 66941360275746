import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]

const SingleContentArea = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h3>EB-5 Visa</h3>
                <h5>About the EB-5 — “Immigrant Investor Green Card”.</h5>
                <p>The EB-5 Immigrant Investor Permanent Residence/Green Card offers permanent residency in U.S. for investors who infuse $1 Million or $500,000, for rural or high unemployment areas, into a US company that will employ 10 or more full-time jobs for US or legal permanent resident employees.</p>
                
                <p>Key Considerations for EB5 Visa</p>
                <h5>EB5 VisaAn EB-5 Investor visa provides some great benefits:</h5>
                <p>Typically faster Green Card than other methods for petitioner and family.
                No requirement of a job offer or labor certification.
                No required disclosure of net worth.
                The opportunity to be passively or actively involved if you wish.
                There has been substantial improvement in the consistency of the EB-5 visa, resulting in more and more confidence in the program by investors.</p>
                <p>The USCIS has relaxed its requirements in the past couple of years to encourage foreign investment, resulting in a four-fold increase in EB-5 petitions since 2008.</p>
                <h5>EB5 Eligibility Criteria</h5>
                
                <h3>New Business</h3>
                <p>Invest or be in process of investing $1,000,000. Or, if investment is in a designated targeted employment area, minimum investment requirement is $500,000.
                Investment can be made in capital, defined as cash, equipment, inventory, other tangible property, cash equivalents and indebtedness secured by assets owned by the international investor, provided that that he/she is personally and primarily liable.
                Must benefit U.S. economy by providing goods or services to U.S. markets
                Create full-time employment for at least 10 U.S. workers. This includes U.S. citizens, Green Card holders, and others lawfully authorized to work in the U.S. It does not include the petitioner or family members. Both direct and indirect jobs are counted.
                Petitioner can be involved in day-to-day management of the new business, or as a limited partner</p>
                
                <h5>Failing Business</h5>
                <p>Business must have existed at least 2 years
                Business must have a current net loss for the 12-24 month period prior to petition
                That loss must be at least 20% of the business’ net worth
                Must maintain job levels no less than the pre-investment level for at least 2 years
                Petitioner involved in the day-to-day management or as a limited partner
                Invest or be in process of investing $1,000,000. Or, if investment is in a designated “Targeted Employment Area,” minimum investment requirement is $500,000</p>
                
                <h5>Terms explained</h5>
                <p>Targeted Employment Area — defined as a rural area or area that has experienced high unemployment of at least 150% of the national average.</p>
                
                Regional Center Program
                Defined as any economic unit involved in promotion of economic growth, increased productivity, job creation. The investment and job creation requirements are the same as standard EB-5.

                Organizers seeking a regional center designation from the USCIS must show:

                Explain how the geographic region will benefit, how the regional center will achieve economic growth.
                Show how the business plan can be relied upon as a viable business model, and based on reasonable and credible estimates of market condition.
                Verifiable detail on how jobs will be created directly or indirectly via capital investments noted in the business plan.
                The amount and source of capital committed to the project, and the promotional efforts made and planned for the project.
                Family of EB5 Visa Holders
                The spouse and unmarried children under 21 years may be admitted to the U.S. on a 2-year conditional period. You may then file a petition (I-829) to remove conditions from family member Green Cards.

                As lawful permanent residents (Green Card holders), your family members will be authorized to work or attend school in the U.S.
                {avatar && (
                    <div className="avatarWra">
                        <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default SingleContentArea