import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

import './style.scss'

const H1B1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h2>H-1B1 Visa</h2>
            <p>The H-1B1 visa is a variant of the <NavLink to="/h1b-visa">H-1B skilled worker</NavLink> visa specifically for citizens of Chile or Singapore who wish to work temporarily in the United States.</p>

            <p>While applicants for the “dual intent” H-1B visas may intend to immigrate into the U.S., H-1B1 visas are strictly for temporary work and are not appropriate for those who may wish to apply for permanent residency at a later date.</p>

            <p>While there is a cap of 65,000 H-1B visas each year, 6,800 of these are reserved for H-1B1s, of which 1,400 are allocated for Chilean nationals and 5,400 for Singaporeans.</p>

            <p>Note that Chilean and Singaporean nationals are not prohibited from applying for the regular H-1B visas. For this reason, people who may wish to remain longer in the U.S. should consider applying for the less restrictive H-1B.</p>

            <p>There are two versions of the H-1B1 visa coming from two special treaties agreed with the United States: The special Singapore visa is called the H-1B1-Singapore and the version for Chile is called the H-1B1-Chile.</p>

            <h3>Allocations for H-1B1 Visas</h3>
            <ol>
                <li>65,000 visas in total are allocated to the capped H-1B visa program.</li>
                <li>Of these, a total of 6,800 are reserved for use for the H-1B1, and of those, 1,400 for Chile and 5,400 for Singapore.</li>
                <li>All approved applications for H-1B1 classification are counted towards these limits, including both applications that result in a new visa being issued and also those that involve a change of status.</li>
            </ol>

            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1teqbdyiqOrLa4Dxvn7GU3LiQfxaGcvEHV1BuWSbxDFoeHmOmzRFQ84coiXt939zZ"></div>
        </div>
    </div>
    )
}
export default H1B1ContentArea