import React from "react";
import { Link } from 'react-router-dom';
import SectionTitle from '../Title';
import './style.scss';

const ServiceArea = ({ className, title, subTitle, services }) => {
    return (
        <div id="section1" className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-4 col-md-6">
                            <div className="serviceWrap">
                                <div className="serviceIcon">
                                    <Link to={service.link}>
                                        <i className={`fi ${service.icon}`}></i>
                                    </Link>
                                </div>
                                <div className="serviceContent">
                                    <h3><Link to={service.link}>{service.title}</Link></h3>
                                    <p>{service.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default ServiceArea;
