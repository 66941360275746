import { createSlice } from '@reduxjs/toolkit';

export const selectedItemsSlice = createSlice({
  name: 'selectedItems',
  initialState: [],
  reducers: {
    setSelectedItems: (state, action) => {
      // Directly return the new state as Redux Toolkit uses Immer under the hood to allow "mutating" logic in reducers
      return action.payload;
    },
  },
});

// Export the action creator generated by createSlice
export const { setSelectedItems } = selectedItemsSlice.actions;

// Export the reducer
export default selectedItemsSlice.reducer;