import React, { useEffect } from 'react';

import './style.scss';

const FLContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h1>Family Law Services</h1>
                <p>Family law matters are often deeply personal and emotionally charged, requiring both sensitivity and a strong legal advocate. Our Family Law team is dedicated to providing compassionate and comprehensive legal support to help you navigate these challenging times. We offer a wide range of services tailored to meet the unique needs of each family we serve.</p>
                
                <h2>Why Choose Us for Family Law?</h2>
                <p>We understand that family law issues can be some of the most difficult experiences you’ll face. Our attorneys are not only skilled in the law but also in the art of listening and understanding the complexities of your situation. We strive to resolve family law disputes with the least amount of stress and disruption possible, ensuring that your rights and the best interests of your family are always protected.</p>

                <h3>Key Areas of Family Law We Cover:</h3>
                <ol>
                    <li><strong>Divorce and Separation</strong></li>
                    <p>Divorce can be a complex process, involving numerous legal and financial considerations. We provide clear guidance and representation to ensure that your rights are protected and that you achieve a fair settlement. We also assist with legal separations, helping you understand your options and make informed decisions.</p>
                    
                    <li><strong>Child Custody and Visitation</strong></li>
                    <p>Determining child custody and visitation arrangements can be one of the most contentious aspects of a divorce or separation. We work to create agreements that prioritize the well-being of your children while respecting your parental rights. Whether through negotiation or litigation, we are committed to achieving a custody arrangement that works for your family.</p>
                    
                    <li><strong>Child Support</strong></li>
                    <p>Ensuring that your child receives the financial support they need is critical. We help establish, modify, and enforce child support orders, ensuring that they are fair and in the best interest of your child. We also assist with navigating complex issues such as income calculation and enforcement of payments.</p>
                    
                    <li><strong>Spousal Support (Alimony)</strong></li>
                    <p>Spousal support, or alimony, can be a complex issue in divorce proceedings. We assist in negotiating and establishing fair spousal support arrangements, whether you are seeking support or are being asked to provide it. Our goal is to ensure that the financial needs of both parties are addressed equitably.</p>
        
                    <li><strong>Adoption and Guardianship</strong></li>
                    <p>Expanding your family through adoption or establishing guardianship is a joyous occasion, but the legal process can be intricate. We guide you through each step, from the initial application to finalization, ensuring that all legal requirements are met for a smooth and successful process.</p>
        
                    <li><strong>Domestic Violence and Protective Orders</strong></li>
                    <p>If you are facing domestic violence, our team is here to help you secure protection through restraining orders or other legal means. We provide urgent legal support to ensure your safety and the safety of your loved ones. We also assist clients who are wrongfully accused of domestic violence, ensuring that their rights are defended.</p>
                </ol>

                <h4>A Compassionate Approach to Family Law</h4>
                <p>Our approach to family law is grounded in compassion and understanding. We recognize that these issues can be highly emotional and personal, which is why we prioritize clear communication and personalized service. We are committed to helping you find the best path forward for you and your family, whether that involves negotiation, mediation, or litigation.</p>

                <h4>Experienced Family Law Attorneys You Can Trust</h4>
                <p>With years of experience in family law, our attorneys are well-equipped to handle even the most complex cases. We are dedicated to providing the highest level of legal representation, ensuring that your family's future is secure. From initial consultations to final resolutions, we stand by your side every step of the way.</p>

                <p>If you need assistance with a family law matter, contact us today to learn how we can help you protect your family's rights and achieve a positive outcome.</p>

                <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2TyY4Uyip3KRU39RIWQzXdMSnHGiaHlBnAyvYSDP4JjIjtoyV39bqrplc5RCkIAJt"></div>
            </div>
        </div>
    );
};

export default FLContentArea;
