import './style.scss'
import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import TeamMember from '../../../components/TeamMember'
import Portfolio from '../../../components/Portfolio'
import PortfolioItems from '../../../data/PortfolioItems'
// import NewsLetter from '../../../components/Newsletter'

// images
import breadcumb from '../../../images/breadcumb/3.jpg'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contact', },
]

const ContactPage = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton 
                    className="headerBottomArea headerBottomAreaStyelOne"     
                />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Contact"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contactUsInfo">
                                <h4>Head Office Address</h4>
                                <span>401 W Main St #300, Norman,<br></br> Oklahoma 73069-1319 USA</span>
                                <h4>Dallas Office Address</h4>
                                <span>VELIE GLOBAL LAW FIRM 555 REPUBLIC DR,<br></br>STE. 490 PLANO, TEXAS 75074</span>
                                <h4>Hyderabad Office Address</h4>
                                <span>KRR Residency, 3rd Floor, H.No: 16-2-100/670,<br></br> Gopal Nagar, Road 1A, Hydernagar(V), Kukatpally, Hyderabad,
                                Telangana, INDIA - 500085.</span>
                                <h4>Phone</h4>
                                <span>(405) 310-4333</span>
                                <h4>Email</h4>
                                <span>information@velielaw.com</span>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contactFormArea">
                            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1I2Hcf85yNnQ8tKpmoklFF5ImjOViI9x9qVEKKEKbhK80I3gO3xdOeC2vPiloROH9"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TeamMember
                    title="Qualified Attorneys "
                    subTitle="Meet Our Experts"
                    className="teamArea"
                    slider={true}
                    extraClass="contactTeamAreaBackground contactTeamContent"
            />
            <Portfolio
                className="portfolioArea"
                title="Immigration Cases"
                subTitle="Our Work"
                items={PortfolioItems}
            />
            <FooterArea />
        </Fragment>
    )
}
export default ContactPage