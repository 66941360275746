import React, { useEffect } from 'react'

import './style.scss'

const O1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>O-1 Visa</h1>
            <p>An O-1 (“Extraordinary Ability”) visa is for individuals who can demonstrate extraordinary ability in either the arts, sciences, education, business, or athletics. It requires the applicant to be among the few that have risen to the top of the field.</p>
                
            <p>An O-1 visa may be prepared for someone that is currently in the U.S. (change of status) or one that is outside the U.S.</p>

            <h2>O-1 Visa Requirements</h2>
            <p>Requires sustained acclaim as evidenced by <strong>at least 3</strong> of the following criteria:</p>
            <ol>
                <li>Received nationally or internationally recognized awards or prizes of excellence in your field.</li>
                <li>You are a member in associations which require outstanding achievements of members as judged by nationally or internationally recognized experts in the discipline or field.</li>
                <li>Your work has been described in professional or major trade publications or major media.</li>
                <li>You have participated as a judge either individually or as a member of a panel of the work of others in the same or related field.</li>
                <li>Your articles have been published in professional or major trade publications.</li>
                <li>You have made original scientific, scholarly, or business-related contributions of major significance in your field.</li>
                <li>You have performed in a leading or critical role for organizations or establishments that have a distinguished reputation.</li>
                <li>You command a high salary or remuneration for your services.</li>
                <li>Other evidence to show your eligibility.</li>
            </ol>

            <h2>Additional O-1 Visa Considerations</h2>
            <h3>Motion picture or TV Industry:</h3>
            <p>Specific criteria are listed for motion picture & T.V. industry O-1 applicants. Please contact Velie Law Firm for this special criteria.</p>

            <h2>Duration of Stay & Extensions</h2>
            <p><strong>Duration - </strong> an O-1 visa is valid for three years or the duration of the event, production, or activity for which admission (whichever is shorter).</p> 
            
            <p><strong>Extensions - </strong> available in unlimited 1-year increments. Must continue with the same position or activity for which you were granted an O-1 visa.</p>

            <h2>O-2 Visa: Essential Support Personnel</h2>
            <p>The O-2 work visa is a nonimmigrant visa which allows essential support personnel of O-1 visa holders in the fields of athletics, entertainment, motion picture, and television production to enter into the U.S. and engage in official activities.</p>
            
            <p>O-2 visa applications are not applicable to personnel in science, business, or education.</p>

            <h2>O-3 Visa: Dependent of O1/O2 Visa Holder</h2>
            <p>O-3 Visas are for dependents (i.e. spouse or unmarried children under age 21) of an O-1 visa or O-2 visa holder and enables the dependent to accompany the primary visa holder on their stay in the United States. O-3 Visa holders may enter the U.S. at the same time as the primary visa holder or at a later date. The O-3 visa permits the holder to study in the U.S. but they are not permitted to work.</p> 
            </div>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1yqe7utYNNMIMAySsBtsSNlduWGSz1h1GKpIJMH2YngotXYmJ4nKIy8240v2XFygz"></div>
    </div>
    )
}
export default O1ContentArea