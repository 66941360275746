import React, { useEffect } from 'react'

import './style.scss'

const DACAContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>DACA Application</h1>
            <p>DACA provides a means for an individual who entered the United States before the age of seventeen to apply for permission to stay and to work in the U.S.</p>
                
            <p>DACA (“Deferred Action for Childhood Arrivals”) was created through an executive order by President Barack Obama in 2012 and then extended in 2014.</p>
                
            <h2>Key Considerations for DACA Application</h2>
            <p>It grants non-immigrant legal status to individuals who entered the U.S. as minors who have participated in U.S. society and who have not been in trouble with the law. It does not, however, provide a step to help the individual to become a U.S. citizen.</p>
                
            <p>Upon successful approval, DACA provides a renewable two-year work permit together with exemption from deportation.</p>
                
            <p>The following qualification criteria may apply among other considerations. The applicant must…</p>
                
            <ol>
                <li><strong>Have arrived</strong> in the United States before their 16th birthday.</li>
                <li><strong>Have lived continuously</strong> in the United States since at least 15 July 2007.</li>
                <li><strong>Be under age 31</strong> on 15 June 2012.</li>
                <li><strong>Have either:</strong>
                    <ol>
                        <li>completed high school (or GED)</li>
                        <li>have been honorably discharged from the U.S. armed forces</li>
                        <li>or be enrolled in school.</li>
                    </ol>
                </li>
                <li><strong>Have no conviction</strong> of a felony or serious misdemeanor.</li>
            </ol>                
            <blockquote>Note that meeting all these criteria does not necessarily mean automatic qualification. Ensure you speak to an experienced immigration attorney to help maximize your chances of a successful application.</blockquote>

            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2Z7RMXuExvVZuIjTQFiLZ2j9Hs47rzNpllJoKLV662PPAVFW6qLEoCru5FisYU3Zx"></div>
        </div>
    </div>
    )
}
export default DACAContentArea