import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import CategorySidebar from '../../../components/CategorySidebar'
import FooterArea from '../../../components/FooterArea'
import EB5BlogContentArea from '../../../components/EB5BlogContentArea'
import RecentPosts from '../../../components/RecentPosts'

// images
import breadcumb from '../../../images/breadcumb/eb5-rate-hike.png'
import './style.scss'


const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'EB-5 Visa Update' }
]

const EB5BlogPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Increased USCIS I-526E Filing Fees"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className="singleArea singlePortfolioArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <EB5BlogContentArea
                                className="singleContentArea"
                            />
                        </div>
                        <div className="col-lg-4 col-12">
                            <aside>
                                <CategorySidebar
                                    title="Category"
                                    className="CategoryWrap"
                                />
                                <RecentPosts
                                    className="recentPostWrapper"
                                />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <FooterArea />
        </Fragment>
    )
}
export default EB5BlogPage