import React from 'react'
import './style.scss'

const BannerSidebar = ({ className, image }) => {
    return (
        <div style={{ backgroundImage: `url(${image})` }} className={className}>
            <h5><span>30</span> Years Immigration Experience for Lead Counsel.
            Our Family Started Practicing Over 
            <br></br>
            <span>100</span> Years Ago</h5>
            <div className="btnStyle btnStyle4">
                <a href='https://calendly.com/onlinevisas/strategy-session-by-velie-global-law-firm-199' rel='noopener noreferrer'>Schedule Your Strategy Session</a>
            </div>
        </div>
    )
}
export default BannerSidebar