import React, { useEffect } from 'react'

import './style.scss'

const IRContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>Green Card for Immediate Relative of U.S. Citizen</h1>
            <p>The <a href='https://www.uscis.gov/green-card/green-card-eligibility/green-card-for-immediate-relatives-of-us-citizen'>USCIS</a> are keen to keep families together so they make special provisions for immediate family members of the U.S. Citizens that can give them easier access to permanent residency through family green card visas.</p>

            <p>The major advantage of this green card is that there is a special immigration priority for immediate relatives making available an unlimited number of visas for immediate relative categories. This means applicants do not have to wait in line for a visa number.</p>

            <h2>Key Considerations for Immediate Relative Green Card</h2>
            <p><blockquote>“Immediate family members”</blockquote> are defined as:</p>
            <ol>
                <li>Spouses</li>
                <li>Parents</li>
                <li>And unmarried children under the age of 21.</li>
            </ol>

            <p>While this is one of the more straightforward green card visas to secure because of the higher level of attempted fraud U.S. Immigration can often be very diligent and go to lengths to get applicants to prove the family relationship beyond doubt.</p>

            <h2>Immediate Relative Green Card Visa Requirements</h2>
            <p>The most basic requirements are met by submitting the <strong>standard documentsstandard documentsb</strong> i.e., birth certificates or marriage certificates, and passing a <strong>medical examination</strong>.</p>

            <p>We recommend that applicants present as much <strong>additional documentation</strong> as they can manage particularly for establishing the veracity of a marriage. These can include joint mortgage or lease documents, loans, bank accounts, car ownership, etcetera.</p>

            <p>It may also be helpful to get <strong>letters of recommendation</strong> from members of your community such as church or religious leaders or work colleagues. Additionally, any <strong>informal evidence of your shared history</strong> that you can present may help your application for example past love letters, other communications, or photos of family occasions.</p>

            <p>Further, you should expect to go through an <strong>interview process</strong> in order to prove that the relationship is bona fide. In these interviews, the authorities will be looking for any indicators of possible fraud. This may include dividing the couple (usually in the case of a married couple's application) to verify that each other's responses match up.</p>

            <h2>Widows and Widowers</h2>
            <p>Widows or widowers of U.S. citizens may be eligible for Immediate Family Member Green Card if:</p>
            <ol>
                <li>The U.S. citizen filed a petition before his or her death</li>
                <li>Or if the widow(er) files a petition within two years of the citizen's death.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1r3iHZ2VVIl2kyYlyxmj51IpHIKDobLaVIPEt2RGBC2AsBcGB2UGJR9heUMJ7Z3qP"></div>
            </div>
    </div>
    )
}
export default IRContentArea