import React, { useEffect } from 'react'

import './style.scss'

const R1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>R-1 Visa</h1>
            <p>The<a href='https://onlinevisas.com/r1-visa/'>R-1</a> (“Religious Organization Worker”) visa is provided to foreign nationals in religious occupations who wish to enter the U.S. to perform duties of the religious worker.</p>

            <h2>R-1 Visa Benefits</h2>
            <ol>
                <li><strong>Study</strong> - may engage in full-time study in the U.S. So can dependents accompanying you under an R-2 dependent visa.</li>
                <li><strong>Travel</strong> - may travel freely in and out and within the U.S.</li>
                <li><strong>Remuneration</strong> - may receive payment for services performed but only from the organization through which R-1 status obtained.</li>
                <li><strong>Green Card</strong> - may apply for permanent residency.</li>
                <li><strong>Family</strong> - may apply for the R-2 dependent visa for your spouse and/or unmarried children under 21 years of age. Dependents however may not work under an R-2 visa (employment requires qualification for working visa). They may study full-time under the R-2 visa.</li>
                <li><strong>Length of Stay</strong> - Initial visa is for up to 30 months (2.5 years).</li>
                <li><strong>Extensions</strong> - you may receive one extension for an additional 30 months (2.5 years) for a total stay of up to 5 years.</li>
            </ol>
            <h2>R-1 Visa Requirements</h2>
            <ol>
                <li>For the two years preceding the application must have been a member of a bona fide non-profit religious organization.</li>
                <li>Be a minister of religion working in a religious vocation or occupation.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1E1Pgl3Ws2xu6q9wfrxaTDJ7ktqSluvLsclsBXVvpm77huQo1mtrVrHOG02WdzFfR"></div>
            </div>
    </div>
    )
}
export default R1ContentArea