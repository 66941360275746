import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import ContactArea from '../../../components/ContactArea'
import Testmonial from '../../../components/Testmonial'

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg'
import team from '../../../images/expert/kevin_vance.png'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Kevin Vance' }
]

const teamDetails = {
    name: 'Kevin Vance',
    position: 'Senior Counsel',
    practiceArea: 'Real Estate, Financial, Titles & Wills, Trusts, Probate, Bankruptcy, and Litigation',
    experience: '24 years',
    offices: 'Plano, Texas',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    memberships: [
        'Texas Bar Association',
        'Dallas Bar Association',
        'American Bar Association'        
    ],
    communityService: [
        'Texas Scottish Rite Hospital',
        'Habitat for Humanity',
        'SPCA'
    ],
    education: [
        'Juris Doctor, South Texas College of Law',
        'Bachelor of Arts, Baylor University'
    ]
};

const SingleTeamPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={`Meet ${teamDetails.name}`}
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={team} alt={teamDetails.name} />
                                        </div>
                                        <h4>{teamDetails.name}</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Important Information</h4>
                                        <ul className="teamMembarInfo">
                                            <li><span>Position: </span>{teamDetails.position}</li>
                                            <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                            <li><span>Experience: </span>{teamDetails.experience}</li>
                                            <li><span>Offices: </span>{teamDetails.offices}</li>
                                            <li><span>Phone: </span>{teamDetails.phone}</li>
                                            <li><span>Email: </span>{teamDetails.email}</li>
                                        </ul>
                                        <ul className="socialShare">
                                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Personal Experience</h3>
                                        <p>Kevin Vance is a seasoned attorney with Velie Law Global, PLLC, specializing in corporate law, real estate, and complex financial transactions. With a rich career that began in Dallas, Texas, Kevin has amassed significant experience in both the private and public sectors. He has served as corporate claims counsel for national and regional firms, advised startups, and held in-house counsel positions for major financial institutions. Kevin's expertise extends to real estate law, disaster lending, and title insurance, where he has guided clients through intricate legal landscapes. Known for his strategic thinking and ability to navigate complex legal challenges, Kevin is a valuable asset to clients ranging from entrepreneurs to large corporations. His commitment to excellence is reflected in the successful outcomes he consistently achieves, ensuring his clients' business goals are met with the highest level of legal proficiency.
                                        </p>
                                        <p>Corporate claims counsel for a national title underwriter, Real estate law (residential and commercial), Mortgage origination and servicing, disaster lending, title insurance, title curative work, wills, trusts, estates, probate, bankruptcy, and litigation</p>                                        
                                        <h5>Memberships</h5>
                                        <ul>
                                            {teamDetails.memberships.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Community Service</h5>
                                        <ul>
                                            {teamDetails.communityService.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Education</h5>
                                        <ul>
                                            {teamDetails.education.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        <h5>Education</h5>
                                        <ul>
                                            {teamDetails.education.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Language</h5>
                                        <span>English (fluent)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactArea className="contactArea" />
            <Testmonial className="testmonialArea" />
            <FooterArea />
        </Fragment>
    )
}
export default SingleTeamPage
