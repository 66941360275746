import React, { useEffect } from 'react'

import './style.scss'

const EB2ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>EB-2 and EB-3 Visas</h1>
            <p>EB-2 and EB-3 visas are permanent residency visas (green cards) for skilled professionals or academics.</p>

            <h2>EB-2 Visa Overview</h2>
            <p><strong>The <a className="visaLinks" href='https://onlinevisas.com/eb2-visa/'>EB-2 Visa</a> or Employment-Based “Exceptional” Ability or Advanced Degree Permanent Residence/Green Card</strong> (Second Preference) is for members of professions holding advanced degrees or equivalent, or “exceptional ability” in sciences, medicine, arts, business, or athletics.</p>

            <p><strong>The <a href='https://onlinevisas.com/eb2-visa/'>EB-3 Visa</a>, or Skilled, Professional, or Other Worker Permanent Residence/Green Card</strong> (Third Preference), is an employment-based petition for those seeking positions requiring 2 years training or experience, professionals whose job requires a baccalaureate degree or equivalent, or “other” unskilled workers (defined as jobs requiring less than 2 years to be proficient).</p>

            <p>The EB-2 is a desirable permanent / green card visa because approval takes much less time than EB-3. However, it is scrutinized more closely. That's why it is even more critical you select an experienced immigration attorney.</p>

            <h3>EB-2 Visa Eligibility Criteria</h3>
            <ol>
                <li><strong>Advanced Degree</strong> - position you are seeking must require an advanced degree, or its equivalent (a baccalaureate degree plus 5 years progressive experience in the field), which you possess.</li>
                <li><strong>Exceptional Ability</strong> - Must be able to show exceptional ability in sciences, arts, business, medicine, or athletics. Exceptional  ability means significantly above that normally encountered. Evidence requires 3 of 7 USCIS criteria (such as academic records, licenses, letters documenting experience, professional association memberships, recognition in your field, exceptional salary, etc.)</li>
                <li><strong>National Interest Waiver (NIW)</strong> - Someone seeking a NIW is requesting the Labor Certification be waived because it is in the interest of the U.S. This means the job offer requirement be waived for the sake of “national interest. ”   Since this the job offer and Labor Certification is waived, an individual no longer requires an employer and thus may self-petition. NIW presents advantages over other employment-based green card options, but are complex and involve scrutiny with USCIS, and it is important you seek assistance from an experienced immigration attorney.</li>
            </ol>

            <h3>EB-2 Labor Certification Process</h3>
            <p>Unlike the EB-1 visa, the EB-2 requires that the petition go through the PERM process. The PERM Labor Certification process is where the U.S. Department of Labor (DOL) requirement to “test the market” to prove there are no qualified U.S. workers for the position. The National Interest Waiver is an exception to this requirement.</p>

            <h3>Family of EB-2 Visa Holders</h3>
            <p>Spouse may be admitted through either E-21, and minor children via E-22. During the process of applying for permanent residency, the spouse may apply for an Employment Authorization Document (EAD), which allows the spouse to freely work in the U.S.</p>

            <h2>EB-3 Visa Overview</h2>
            <h3>Key Features of the EB3 Visa</h3>

            <p>The EB-3 has a lower threshold to meet, and is thus less scrutinized, but there is also a wait list for approval in many cases.</p>

            <p>Most EB-3 Permanent Residency Visas can not be adjusted from temporary or non-immigrant visas to Permanent Residency Visas or Green cards until the petitioner's priority date becomes current. Priority dates are established when the labor certification process is initiated.</p>
            <p>The Department of Labor posts the priority dates of Permanent Residency Visas that USCIS is processing each month.</p>

            <h3>EB-3 Visa Eligibility Criteria</h3>
            <ol>
                <li><strong>Skilled Workers</strong> - Jobs that require at least 2 years job experience or training. Must demonstrate qualified workers are not available in the U.S.</li>
                <li><strong>Professionals</strong> - Job requires a baccalaureate degree or foreign equivalent, and a member of the profession.</li>
                <li><strong>Other Workers</strong> - Positions that can be undertaken with less than 2 years training, and that are not of a temporary or seasonal nature.</li>
            </ol>

            <h3>EB-3 Labor Certification Process</h3>
            <p><strong>The EB-3 requires that the petition go through the PERM Labor Certification process.</strong></p>

            <p>The PERM Labor Certification process is where the U.S. Department of Labor (DOL) requires the employer to “test the market” to prove there are no willing or qualified U.S. workers for the position.</p>

            <p>The process requires obtaining a Prevailing Wage Determination. Posting with the relevant State Workforce Agency, placing advertisements in the newspaper of major circulation for consecutive Sundays, posting in a conspicuous place at the worksite and on the company's intranet as well as selecting three other recruitment processes from the regulations.</p>

            <p>It is critical that the employer conducts and records all actions taken during the recruitment component of the labor certification process and that it can be advised and understand best methods from an experienced Immigration law firm like Velie Law Firm.</p>

            <p>In some cases the petition, may be submitted directly to USCIS without a labor certification, for  Schedule A, Group I cases.</p>

            <h2>EB3 Visa Application Process</h2>
            
            <p>The employer must file an I-140 with USCIS and establish that it has the ability to pay the offered position upon receipt of the visa, and that the beneficiary can meet the requirements.</p>
            
            <h3>Family of EB-3 Visa Holders</h3>
            
            <p>Spouse may be admitted through either E34 (“skilled” worker) or EW4 (“other” worker). Minor children are admitted via E35 (“skilled”) or EW5 (“other” worker)</p>
            
            <p>During the process of applying for permanent residency, the spouse may apply for an Employment Authorization Document (EAD), which allows the spouse to freely work in the U.S.</p>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1yr2p301pxz1hW7uTEZ24kUISGV1YwqPKtlYjHBeVJ3yauEKKImnHKgIkCb7NiRhx"></div>
            </div>
    </div>
    )
}
export default EB2ContentArea