import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import About from '../../../components/About'
import Testmonial from "../../../components/Testmonial"
import TeamMember from '../../../components/TeamMember'
import FooterArea from '../../../components/FooterArea'
import about from "../../../images/about/1.jpg"
import './style.scss'

const aboutText = [
    { text: 'We are dedicated to one goal: getting your visas approved. With 30 years of experience, we have maintained a consistent 99% approval rate thanks to our revolutionary proprietary technology, Visas.AI. ' },    
    { text: 'Visas.AI, the Intelligent Immigration Platform™ combines expert immigration legal strategy with a highly efficient visa-building process to guarantee success. We represent a diverse range of clients across various industries and have developed Visas.AI to provide our clients with an unparalleled experience.' },
    { text: 'Culture is the heartbeat of Velie Global Law Firm. Our Why is Delivering Dreams—a mission that has been at the heart of our work for over three decades, and a legacy passed down through my family for more than a century. Like the generations before us, we\'ve planted seeds that have grown into a vast forest of fulfilled dreams.' },
    { text: 'Our journey traces back to Jon\'s Great, Great Grandfather, Abraham Rockmore, a Jewish-Ukrainian immigrant who, at the young age of 13, entered Columbia Law School in New York City. His pivotal work post-World War II brought Holocaust victims to America, advocating for them in Congress and contributing to the passage of the Naturalization Act of 1947. His legacy of advocacy for immigrant families laid the foundation for our commitment to justice.' },
    { text: 'In 1993, Jon Velie founded the first iteration of our firm, a solo practice where he took on any legal challenge that came his way. From a DUI trial victory to groundbreaking cases securing citizenship for Seminole and Cherokee Freedmen, Jon\'s commitment to justice has been unwavering. These cases garnered international attention, featured in prestigious publications and documentaries, showcasing our dedication to making a lasting impact.' },
    { text: 'Our foray into immigration law in 1994 marked the beginning of a national practice, later expanding to represent top sports organizations like Aspen Ski Company and Major League Rugby. The inception of OnlineVisas.com in 1998, recognized by the American Bar Association for legal innovation, the establishment of Online Visas, Inc. in 2016, and the invention and development of Visas.AI are milestones that set us apart as industry innovators.' },
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About us'}
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">                
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={about}
            />
            <About 
                className="aboutArea aboutAreaStyleTwo"
                title="Why You Need Us"
                subTitle="We Are Expert"
                paragraphs={aboutText}
                showButton={false}
                showImage={false}
            />
            <Testmonial
                className="testmonialArea pt100"
            />            
            <TeamMember 
                title="Qualified Attorneys"
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            /> */}
            {/* <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default AboutPage