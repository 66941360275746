import React, { useEffect } from 'react';

import './style.scss'

const E1ContentArea = ({ className, image }) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>E-1 Visa</h1>
            <p>The E-1 visa is for “Treaty Traders” who are individuals from any of 30+ countries that have treaties with the United States.</p> 
                
            <p>Like its sister visa the “E-2 Visa—Treaty Investor” the E-1 Visa allows the trader to open a company in the United States and operate that company.</p> 
                
            <p>The treaty trader is a little different to the E-2 (treaty investor) because it requires trade between the visa holder's home country and the United States.</p>

            <h2>Key Considerations for E-1 Visa</h2>
            <p>E-1 Treaty Trader visa is for individuals or employees entering the U.S. to engage in <strong>international trade.</strong></p>
            <p>The definition of “trade” between the visa holder's home country and the US is pretty broad. It can cover goods services banking and many other forms of legal trade between the two countries. (See Requirements below.)</p><p>The E-1 may also be issued for immediate family - spouse and children.</p>

            <h3>E-1 Visa Requirements</h3>
            <ol>
                <li><strong>Applicants must be nationals of a country</strong> the U.S. maintains a treaty. Note that this is not a requirement for family members.</li>
                <li><strong>Carry on “substantial” trade</strong> which refers to continuous flow of sizable international trade items. There is no minimum monetary value or volume but greater weight is given to the number of exchanges versus monetary value.</li>
                <li><strong>Trade items</strong> include goods services banking insurance transportation tourism technology news-gathering.</li>
                <li><strong>Carry on principal trade</strong> between U.S. and treaty country which is defined as over 50% of total trade volume of the trader.</li>
            </ol>

            <h3>Requirements of E-1 “Employee of Treaty Trader”</h3>
            <p>Employee must:</p>
            <ol>
                <li>Be the same nationality of the principal employer (who must be a national of a treaty country).</li>
                <li>Meet definition of “employee” under relevant law.</li>
                <li>Be engaging in supervisory or executive capacity or have relevant special qualifications.</li>
            </ol>

            <h3>Additional E-1 Visa Terms</h3>
            <p><strong>Period of Stay</strong> - up to two years. Extensions may be granted in increments of two years with no maximum number of extensions limit.</p>
            <p><strong>Family members</strong> - includes spouses or children under 21 years. Nationality need not be the same. Spouses may be approved for work.</p>
            <p><strong>Travel abroad</strong> - travel is permitted for the treaty trader or employee and an automatic 2-year extension granted upon re-admission. This new readmission period will not be granted automatically to family members who should carefully monitor the period of stay and apply for extension to remain in the U.S. lawfully.</p>
            <p><strong>Other employment</strong> - An E-1 trader or employer may only work in the activity he/she was approved for at time of E-1 issuance. However, he/she may also work as the treaty organization's parent in some circumstances.</p>
            </div>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1uN4t8bMjNFRIvA6ARGrGFIdR3tH3jM6NngeeyyJVZWM0xbIbVzdaaHLlxFBFIHyX"></div>
        </div>
    );
};
export default E1ContentArea