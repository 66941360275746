import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const H1BCapUpdateBlogContentArea = ({ className }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
            <h2>Important Updates for FY 2025 H-1B Cap Season and Form I-129 Filing</h2>
            <p>As the FY 2025 H-1B cap season progresses, the <a href="https://www.uscis.gov/" target="_blank" rel="noopener noreferrer">United States Citizenship and Immigration Services (USCIS)</a> has introduced several significant updates and reminders regarding the filing process and procedural changes. These updates aim to streamline the application process and ensure compliance with new regulations. Here's what you need to know:</p>

            <h3>FY 2025 H-1B Cap Registration Period Closed</h3>
            <p>The initial registration period for the FY 2025 <Link to="h1b-visa">H-1B</Link> cap season concluded on March 25, 2024. USCIS will soon conduct random selections of beneficiaries from properly submitted registrations to fulfill the numerical allocations for the FY 2025 H-1B cap. Selected petitioners will be notified of their eligibility to file H-1B cap-subject petitions for their beneficiaries.</p>

            <h3>New Fees and Form Edition</h3>
            <p>Effective April 1, 2024, USCIS will implement new fees for most immigration applications and petitions. Additionally, a new edition of <a href="https://www.uscis.gov/sites/default/files/document/forms/i-129.pdf" target="_blank" rel="noopener noreferrer">Form I-129</a>, Petition for a Nonimmigrant Worker, dated 04/01/24, will be required for filings postmarked on or after April 1, 2024. It's essential to ensure compliance with the new fee structure and use the correct form edition to avoid rejection.</p>
            
            <h3>Online Filing and Organizational Accounts</h3>
            <p>USCIS has introduced new online organizational accounts to facilitate collaboration on and preparation of H-1B registrations, petitions, and associated forms. Online filing of Form I-129 and associated Form I-907 for non-cap H-1B petitions commenced on March 25, with online filing for H-1B cap petitions scheduled to begin on April 1. Petitioners will have the option to file electronically or via paper forms.</p>

            <h3>No More Prepaid Mailers</h3>
            <p>As of March 25, 2024, prepaid mailers are no longer used for any communication or final notices related to H-1B or H-1B1 (HSC) petitions. USCIS will rely on automated processes for printing and mailing approval notices, with notifications sent to MyUSCIS account holders via email or text message.</p>
            
            <h4>Conclusion</h4>
            <p>Navigating the H-1B cap season and understanding USCIS updates can be complex. At Onlinevisas, our team of immigration experts is dedicated to assisting clients with their U.S. immigration needs. Book your slot now for a Strategy Session to discuss your case and explore the best pathway forward. Don't miss out on this opportunity to secure your future in the United States!</p>
            <br></br>
            </div>
        </div>
    )
}
export default H1BCapUpdateBlogContentArea