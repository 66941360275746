import React, { useEffect } from 'react'

import './style.scss'

const P3ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>P-3 Visa</h1>
            <p>The <a href='https://onlinevisas.com/p3-visa'>P-3 visa</a> known as the “Artists or Entertainers In Culturally Unique Program” visa is for artists or entertainers coming to the United States to:</p>
            <ol>
                <li>Perform in a culturally unique program.</li>
                <li>Represent, teach, coach in cultural, musical, ethnic, folk, artistic, or theatrical arts.</li>
                <li>Or as support personnel to P-3 holders.</li>
            </ol>

            <h2>P-3 Visa Benefits and Limitations</h2>
            <ol>
                <li><strong>Commercial or Non-commercial</strong> - programs may be either commercial or non-commercial in nature.</li>
                <li><strong>Level of performance</strong> - a lower threshold allowable compared to P-1 visa but level of performance of the U.S. and visiting performers should be similar.</li>
                <li><strong>Duration</strong> - Visit is for a period necessary to complete the program or event, up to a maximum of 1 year.</li>
                <li><strong>Extensions</strong> - P-3 holders may be extended in increments of 1 year if the same event or activity was originally admitted.</li>
                <li><strong>Payment</strong> - is allowed under a P-3 visa.</li>
                <li>Study - Undertake part-time study.</li>
                <li>Support personnel - may apply for a P-3 visa to accompany principal P-2 visa holders.</li>
                <li>Travel - May Travel freely inside and outside the U.S. while a P-3 visa is valid.</li>
                <li>Dependents - Spouse and/or unmarried children under 21 years old may accompany or visit under a P-4 visa. They may not be employed on a P-4 visa, however.</li>
                <li><strong>Permanent residency / Green Card</strong> - P-3 visa holders may apply for an Adjustment of Status and seek permanent residency (this does not include support personnel).</li>
                <li>Dependents & employment - dependents on P-4 visa may not work.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2VLqZK7HTZ8w1rNmLoXb7k9ipHO7hU3PTjif31W60bxNWB34ST1jYulhkDVJkZJWX"></div>
            </div>
    </div>
    )
}
export default P3ContentArea