import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const WorkContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h1>U.S. Work Visa</h1>
                <p>View the list of temporary and permanent residencies in the United States for work visas below then set up a strategy session with one of our top attorneys to evaluate your case.</p>
                <p>We will help you to figure out the right visa to apply for and help assess your chance of success and what you may have to do in order to secure your US work visa successfully.</p>

                <h2>U.S. Temporary Work Visas</h2>
                <nav className='visaLinks'>
                    <ul>
                        <li><Link to="/b1-visa"><strong>B-1 & B-2 Visa</strong></Link> - Business or Pleasure Visitor</li>
                        <li><Link to="/e1-visa"><strong>E-1 Visa</strong></Link> - Treaty Trader</li>
                        <li><Link to="/e2-visa"><strong>E-2 Visa</strong></Link> - Treaty Investor</li>
                        <li><Link to="/e3-visa"><strong>E-3 Visa</strong></Link> - Specialty Occupation (Australia)</li>
                        <li><Link to="/h1b-visa"><strong>H-1B Visa</strong></Link> -Specialty Occupation</li>
                        <li><Link to="/h3-visa"><strong>H-3 Visa</strong></Link> - Trainee / Special Education</li>
                        <li><Link to="/i-visa"><strong>I Visa</strong></Link> - Foreign Media</li>
                        <li><Link to="/j1-visa"><strong>J-1 Visa</strong></Link> - Cultural / Educational Exchange</li>
                        <li><Link to="/l1-visa"><strong>L-1 Visa</strong></Link> - Intra-Company Transfer</li>
                        <li><Link to="/o1-visa"><strong>O-1 Visa</strong></Link> - Extraordinary Ability</li>
                        <li><Link to="/p1-visa"><strong>P-1 Visa</strong></Link> - Athlete / Coach</li>
                        <li><Link to="/p3-visa"><strong>P-3 Visa</strong></Link> - Artists / Entertainers</li>
                        <li><Link to="/r1-visa"><strong>R-1 Visa</strong></Link> - Religious Worker</li>
                        <li><Link to="/tn-visa"><strong>TN Visa</strong></Link> - NAFTA Professional (Canada / Mexico)</li>
                    </ul>
                </nav>
                <h2>U.S.A. Permanent Residency Visas (Green Cards)</h2>
                <nav className='visaLinks'>
                    <ul>
                        <li><Link to="/eb1a-visa"><strong>EB-1A Visa</strong></Link> - Extraordinary Ability</li>
                        <li><Link to="/eb1b-visa"><strong>EB-1B Visa</strong></Link> - Outstanding Researcher / Professor</li>
                        <li><Link to="/eb1c-visa"><strong>EB-1C Visa</strong></Link> - Multinational Executive / Manager</li>
                        <li><Link to="/eb2-visa"><strong>EB-2 Visa</strong></Link> - Advanced Degree / Exceptional Ability</li>
                        <li><Link to="/eb3-visa"><strong>EB-3 Visa</strong></Link> - Skilled Worker / Professional</li>
                        <li><Link to="/eb5-visa"><strong>EB-5 Visa</strong></Link> - Immigrant Investor</li>
                    </ul>
                </nav>
                <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1GbaL5O7k1TGPkYaE24tIA2GTLHmZ5vUa33d0nZx75uLrUX1cXH1OjfSQwHBpHW4X"></div>
            </div>
        </div>
    );
};

export default WorkContentArea;
