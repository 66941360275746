import React from 'react'
import './style.scss'

const services = [
    { icon: 'fi flaticon-house', title: 'Relationship' },
    { icon: 'fi flaticon-scale', title: 'Strategy' },
    { icon: 'fi flaticon-parents', title: 'Knowledge' },
    { icon: 'fi flaticon-employee', title: 'Briefing' },
    { icon: 'fi flaticon-network', title: 'Technology' },
]
const Service = props => {
    return (
        <div className={props.className}>
            <div className="container">
                <h2 className="brand-promises-heading">Brand Promises</h2>
                <div className="row">
                    {services.map((service, i) => (            
                            <div key={i} className="col-10 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                <div className="serviceItem">
                                    <div className="serviceIcon">
                                        <i className={service.icon}></i>
                                    </div>
                                    <div className="serviceText">
                                        <span>{service.subtitle}</span>
                                        <h3>{service.title}</h3>
                                    </div>
                                </div>
                            </div>                        
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Service