import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'

// images
import testmonial from '../../images/testimonial/testi.jpg'
import testmonial2 from '../../images/testimonial/nigel-melville.jpg'
import testmonial3 from '../../images/testimonial/pierre-subeh.jpeg'
import testmonial4 from '../../images/testimonial/vishn-hari.jpg'
import testmonial5 from '../../images/testimonial/taha-imam.jpg'
import testmonial6 from '../../images/testimonial/greg-minaar.jpg'
import testmonial7 from '../../images/testimonial/ken-sobajima.jpg'
import testmonial8 from '../../images/testimonial/liliaba-saraiva.jpg'

const sliders = [
    {
        text: 'Professional presentation, excellent content - clients should be advised of the value of providing as much information as possible. It was reassuring to know exactly what our costs were at the outset. Everything appeared to go more smoothly with you than another practitioner we were using. Efficiency is important to me and this process, we have always received prompt responses to queries',
        images: testmonial2,
        title: 'Nigel Melville',
        subTitle: 'Executive Chairman at PRL Investor Board'
    },
    {
        text: 'Booking a Strategy Session on this website was the beginning of a new chapter, I spoke to Jon and his team took me in and built out a strategic approach backed by authority turning my career\'s achievements into evidence. On March 3rd, 2023, I started my new dream, living and working in the United States.',
        images: testmonial3,
        title: 'Pierre Subeh',
        subTitle: 'CEO of X Network'
    },
    {
        text: 'My attorney used Visas.Al to provide a comprehensive strategy for my O-1 extraordinary ability visa, built over ten testimonial and expert letters, an employment agreement and a comprehensive legal brief in one sitting.',
        images: testmonial4,
        title: 'Vish Hari Kumar',
        subTitle: 'CEO of Ego.live'
    },
    {
        text: 'We started our engagement with Jon\'s team at Velie Law few years back with few H-1B Cap Cases. We had a 100% success rate. His team provides excellent communication and follow ups.',
        images: testmonial5,
        title: 'Taha Imam',
        subTitle: 'Managing Director at Sky Systems, Inc.'
    },
    {
        text: 'Thank you Velie Law Firm for you outstanding service in getting the necessary documents required for obtaining my US Visa. I\'ve never experienced such high level of service and communicating in dealing with daunting task of Visa\'s',
        images: testmonial6,
        title: 'Greg Minnaar',
        subTitle: 'Four Time Moutain Bike World Champion'
    },
    {
        text: 'As a minority from Japan, I was desperate. After hearing hundreds of attorneys calling my dream impossible, I was losing my confidence and hopes until I met the Veile Law Firm. Rather than denying my possibility immediately, they first believed in me and found it along the way. My EB-1 (Extraordinary Ability) -- Green Card -- was my ultimate dream come true.',
        images: testmonial7,
        title: 'Ken Sobajima',
        subTitle: 'Founder & CEO of Tomorrow Access'
    },
    {
        text: 'Going through a work visa process is always stressful and challenging but with Velie Law firm team\'s support, the entire journey was extremely positive. From their support answering the phone any time I had a question, helping me fill all the paperwork and guiding me through the entire process, their were incredible After a few months, I got my L-1 visa.',
        images: testmonial8,
        title: 'Liliana Saraiva',
        subTitle: 'Global Head of Brands at Company of Animals'
    }
]

const settings = {      
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
};

const Testmonial = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="testimonialImages">
                            <img src={testmonial} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-6">
                        <div className="testimonialContent">
                            <span>What People Say</span>
                            <h2>Client Testimonial</h2>
                            <Slider className="testmonialSlider" {...settings}>
                                {sliders.map((slider, i) => (
                                    <div key={i} className="slideItem">
                                        <p>{slider.text}</p>
                                        <div className="thumbWrap">
                                            <div className="thumbImg">
                                                <img src={slider.images} alt="" />
                                            </div>
                                            <div className="imgContent">
                                                <h4>{slider.title}</h4>
                                                <span>{slider.subTitle}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testmonial