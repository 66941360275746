import React, { useEffect } from 'react';
import './style.scss';

const PipeDriveForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1rq6I4dMTfHST6gW6ptP9drk32uM5HjaG1pw5gwnyALPd5ergF7fMuJvSafFKG9tp"></div>
    );
}

export default PipeDriveForm;
