import React, { useEffect } from 'react'

import './style.scss'

const H3ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>H-3 Visa</h1>
            <p>The H-3 Trainee visa is for those coming to the U.S. for a temporary period in order to attend an established training program.</p>
            <p>The primary purpose of the <a href='https://onlinevisas.com/h3-visa/'>H-3 visa</a> is to provide foreign employees of U.S. companies the training they need which is often otherwise unavailable in a particular field - such as business communications, agriculture, and transportation (to name a few examples).</p>

            <h2>H-3 Trainee Work Visa Requirements</h2>
            <p>H-3 visa may be one of two types:</p>
            <ol>
                <li><strong>Trainee</strong> - for training not available in petitioners' home country</li>
                <li><strong>Special Education Visitor</strong> - to participate in special education training for children with physical, mental, or emotional disabilities.</li>
            </ol>

            <h3>Additional Requirements</h3>
            <ol>
                <li><strong>Duration</strong> - H-3 Trainees may stay in the U.S. up to 2 years and Special Education Visitors for 18 months. There are no extensions but you may apply for a Change of Status.</li>
                <li><strong>Travel</strong> - may travel freely in and out of the U.S. while visa is valid (but time outside the U.S. still counts as time against visa duration).</li>
                <li><strong>Family</strong> - Trainee's spouses and children under 21 may accompany them to the U.S. but are not permitted to work.</li>
            </ol>

            <h2>H-3 Visa Employer or Organization Requirements</h2>
            <p>The U.S. employer or organization should comply with the following:</p>
            <ol>
                <li>Cannot be conducted with the intention of employing the H-3 recipient - the intent of the training is for work that will ultimately occur outside the U.S.</li>
                <li>Provide detailed description of the training program including hours per week in classroom and in on-the-job training.</li>
                <li>Summary of trainee's prior experience and why he/she needs the training.</li>
                <li>Explanation why training isn't available in trainee's home country.</li>
                <li>Statement of who will pay for the training without the petitioner permanently employing the trainee.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1AER5wL1UEyog4lnZ0Sj8wL1fvcaL8PCal3MRcCUF84RTrzGd7TiiF0fgXov9ouHx"></div>
            </div>
    </div>
    )
}
export default H3ContentArea