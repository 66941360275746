import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import CategorySidebar from '../../../components/CategorySidebar'
import FooterArea from '../../../components/FooterArea'
import H1BCapUpdateBlogContentArea from '../../../components/H1BCapUpdateBlogContentArea'
import RecentPosts from '../../../components/RecentPosts'

// images
import breadcumb from '../../../images/breadcumb/H-1B-Cap-FY2025-Update.jpg'
import './style.scss'


const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'H-1B Cap Updates' }
]

const H1BCapUpdateBlogPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Important Updates"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className="singleArea singlePortfolioArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <H1BCapUpdateBlogContentArea
                                className="singleContentArea"
                            />
                        </div>
                        <div className="col-lg-4 col-12">
                            <aside>
                                <CategorySidebar
                                    title="Category"
                                    className="CategoryWrap"
                                />
                                <RecentPosts
                                    className="recentPostWrapper"
                                />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <FooterArea />
        </Fragment>
    )
}
export default H1BCapUpdateBlogPage