import './style.scss'
import React, { Fragment, useEffect } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import Breadcumb from '../../../components/Breadcumb';
import FooterArea from '../../../components/FooterArea';
import TeamMember from '../../../components/TeamMember';
import Portfolio from '../../../components/Portfolio';
import PortfolioItems from '../../../data/PortfolioItems';

// images
import breadcumb from '../../../images/breadcumb/webinar-v6.svg';
import Testmonial from '../../../components/Testmonial';

const breadcumbMenu = [
    { name: '', route: '#' }
]

const WebinarPage = () => {

useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://webforms.pipedrive.com/f/loader";
    script.async = true;
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    }
}, []);

    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
                noGradient={true}
            />
                <div className="container webinarArea px-2">
                    <div className="row gx-5">
                        <div className="col-lg-5 pr-4">
                            <div className="contactUsInfo">
                                <h2>Predictions for H-1B FY 2025 Cap Season by OnlineVisas</h2>
                                <p>
                                Benefit from Jon Velie's wealth of experience in immigration law as he provides a thorough analysis<br></br> of the H-1B FY 2025 Cap Season and ask questions at the Webinar.</p>

                                <h2>About The Webinar:</h2>
                                <p>Embark on an enlightening journey with us at the "Predictions for H-1B FY 2025 Cap Season" webinar, presented by OnlineVisas and hosted by the esteemed<br></br> 30-year Immigration Attorney, Jon Velie. The anticipated duration of this insightful session remains approximately<br></br> 1 hour.</p>
                                
                                <p><strong>Event Highlights & Expert Insights:</strong> Benefit from Jon Velie's wealth of experience in immigration law as he provides a thorough analysis of the H-1B FY 2025 Cap Season.Policy</p>
                                
                                <p><strong>Updates:</strong> Stay abreast of recent policy changes that could impact the H-1B application process.Interactive Q&A Session: Directly engage with Jon Velie during the dedicated Q&A session to get your specific questions addressed.</p>

                                <p><strong>Free Admission:</strong> Attendance to this informative webinar is free of charge.</p>
                                
                                <p><strong>Camera Optional:</strong> Feel free to join with or without your camera.Don't miss the chance to enhance your understanding of the H-1B visa landscape and gain valuable insights for the upcoming fiscal year. Secure your spot by registering now and join us via Zoom for an hour of illuminating discussion.</p>
                            </div>
                            <div className="btnStyle btnStyle3"><a href="https://calendly.com/velie-law-firm/strategy-session">Read our Collateral</a></div>
                            <br></br>
                        </div>
                        <div className="col-lg-7 pl-4">
                            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1Cao1BUZMysD2tqAWq6ttEj5o4t5PHi8YUXyZm5XkjapntgUwFlCNozAyXn2YqGEH"></div>
                        </div>
                    </div>
                </div>
                <TeamMember
                    title="Qualified Attorneys "
                    subTitle="Meet Our Experts"
                    className="teamArea"
                    slider={true}
                    extraClass="contactTeamAreaBackground contactTeamContent"
                />
                <Portfolio
                    className="portfolioArea"
                    title="Immigration Cases"
                    subTitle="Our Work"
                    items={PortfolioItems}
                />
                <Testmonial
                    className="testmonialArea"
                />
                <FooterArea />
        </Fragment>
    )
}
export default WebinarPage