import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './style.scss'

const IEPContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>International Entrepreneur Rule</h1>
            <p>In late August 2016, U.S. President Barack Obama announced a new type of immigrant visa which clearly aims to boost the U.S. economy by attracting more startup entrepreneurs to the United States, which the administration hopes will create jobs and stimulate growth.</p> 
            
            <p>Currently described as the “International Entrepreneur Rule”, this visa type is expected to come into effect before President Obama leaves office on January 20, 2017.</p> 
            
            <p>This new development is particularly interesting to international entrepreneurs, because it fills a critical gap in U.S. immigration options.</p>
            
            <h2>The International Entrepreneur Rule Offers Two Admission Tiers</h2>

            <h3>Tier 1: First two years</h3>
            <p>The first option for the International Entrepreneur Rule allows you to stay in the United States temporarily for up to two years. To qualify under the first option...</p>
            <ol>
                <li>The business must have been started in the United States in the past three years and must be able to demonstrate potential for rapid growth and new job creation. You would need to provide this by showing...
                    <ol>
                        <li>you have received significant investment capital worth at least US$345,000 from qualified U.S. investors;</li>
                        <li>or have received significant awards or grants totaling at least $100,000 from certain specified federal or state government bodies.</li>
                    </ol>
                </li>
                <li>As one of the company's founders, you must still own at least 15% of the startup and play an active, central role in its operation.</li>
            </ol>

            <h3>Tier 2: Three-year extension</h3>
            <p>The second tier permits an additional three years' stay. Requirements for founders are...</p>
            <ol>
                <li>That you continue to run their business within the U.S.</li>
                <li>retain at least 10% equity,</li>
                <li>plus having raised at least $500,000 from U.S. investors,</li>
                <li>and either demonstrate $500,000 in annual revenue with 20% growth, or prove you have created at least 10 full-time positions over the 5-year span.</li>
            </ol>
            <p>(This makes Tier 2 similar to the <NavLink to="/eb5-visa">EB-5</NavLink> Permanent Residency Green Card, but is somewhat less restrictive, because the EB-5 requires that the overseas entrepreneur must also be the primary investor.)</p>

            <p>Overall, we believe the International Entrepreneur Rule is likely to prove a good path leading to permanent residency within the United States via <NavLink to="/eb1-visa">EB-1A</NavLink>, <NavLink to="/eb1b-visa">EB-1B</NavLink>, <NavLink to="/eb1c-visa">EB-1C</NavLink>, <NavLink to="/eb2-visa">EB-2</NavLink>, <NavLink to="/eb2-visa">EB-3</NavLink>, or <NavLink to="/eb5-visa">EB-5 Green Card</NavLink>.</p>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2TySLLKsYs2NFjn1PgVh1yynErwniiHCKOdET8Pd0VJhKAUyI2SQ6QP5osnZOg5LZ"></div>
            </div>
        </div>
    )
}
export default IEPContentArea