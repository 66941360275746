import React, { useEffect } from 'react'

import './style.scss'

const EB1BContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>EB-1B Visa</h1>
            <p>The <a href='https://onlinevisas.com/eb1b-visa/'>EB-1B</a> Employment-Based Permanent Residence/Green Card (First Preference) is the academic equivalent of the EB-1B and gives permanent residence in the United States to outstanding professors or researchers.</p>

            <h2>EB-1B Visa Overview</h2>
            <p>Like the EB-1B, the <a href='https://onlinevisas.com/eb1b-visa/'>EB-1B</a> visa is one of the more desirable permanent/green card visas because the <strong>priority dates are current</strong> which means there can be far less waiting time compared to other categories.</p>
            <p>However, it is more difficult to qualify for and applications are scrutinized very closely. It is even more critical that you select a very experienced immigration attorney.</p>
            <p>The EB-1B is also desirable because there isn't a requirement to go through the PERM process. The PERM process is where the U.S. Department of Labor (DOL) requirement to “test the market” to prove there are no qualified U.S. workers for the position.</p>

            <h2>EB-1B Eligibility Criteria</h2>
            <ol>
                <li>Applicants must demonstrate international recognition for outstanding performance in a particular academic field.</li>
                <li>Must have at least 3 years experience in teaching or research in that academic area.</li>
                <li>You must be entering the U.S. to pursue tenure or tenure-track teaching or research position at a university or comparable educational institution.</li>
                <li>Evidence must meet 2 of 6 USCIS criteria.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1yNvia1bcWdt7y1JJAwqwEgX0xbhsjj3nTc69tLpbCVDMBeSFIGjr252ivhg256DN"></div>
            </div>
    </div>
    )
}
export default EB1BContentArea