import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import './style.scss'

const K4ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>K-4 Visa</h1>
            <p>The K-4 visa is for a child or children of a K-3 Spouse Visa holder.</p>

            <h2>Criteria for K-4 Child Visa Qualification</h2>
            <ol>
                <li>Applicant must be less than 21.</li>
                <li>And must be the unmarried child of a <Link to="/k3-visa">K-3</Link> applicant.</li>
            </ol>

            <h2>K-4 Visa Additional Requirements & Terms</h2>
            <h3>Employment</h3>
            <p>May apply for a work permit from the USCIS by way of an Employment Authorization Document (EAD). You cannot begin work until the EAD has been granted.</p>

            <h3>Study</h3>
            <p>You may study under a K-4 visa.</p>

            <h3>Length of Stay</h3>
            <p>A <Link to="/k2-visa">K-2</Link> visa is valid for 2 years or until age 21, whichever comes first.</p>

            <h3>Extension</h3>
            <p>K-4 visas must be extended at the same time as your K-3 parent extension. Extensions must be applied for 120 days prior to expiration and are issued in 2-year intervals.</p>
            </div>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/31jwXP5evbWNTa5Hla4SRadgXvq93cDhdN7F2FuFKUtDEhOrL6LAykeh1jCaEUy9Z"></div>
    </div>
    )
}
export default K4ContentArea