import React from 'react';
import './style.scss';
import businessInsiderLogo from '../../images/logo/business-insider-v2.png';
import usaTodayLogo from '../../images/logo/usa-today-v2.png';
import buzzFeedLogo from '../../images/logo/buzzfeed-v2.png';
import laWeeklyLogo from '../../images/logo/la-weekly-v4.png';
import ailaLogo from '../../images/logo/aila-logo-v2.png';
import ritzHeraldLogo from '../../images/logo/ritz-herald-v2.png';
import foxNewsLogo from '../../images/logo/fox-news-v2.png';

const defaultFeaturedData = [
    { id: 1, logo: businessInsiderLogo, alt: 'Business Insider Logo', link: 'https://www.businessinsider.com/' },
    { id: 2, logo: foxNewsLogo, alt: 'Fox News Logo', link: 'https://www.foxnews.com/' },
    { id: 3, logo: usaTodayLogo, alt: 'USA Today Logo', link: 'https://www.usatoday.com/' },
    { id: 4, logo: ailaLogo, alt: 'American Immigration Lawyers Association Logo', link: 'https://www.aila.org/' },
    { id: 5, logo: buzzFeedLogo, alt: 'BuzzFeed Logo', link: 'https://www.buzzfeed.com/' },
    { id: 6, logo: ritzHeraldLogo, alt: 'Ritz Herald Logo', link: 'https://ritzherald.com/' },
    { id: 7, logo: laWeeklyLogo, alt: 'LA Weekly Logo', link: 'https://www.laweekly.com/' },
];

const FeaturedOn = ({ customLogos = [], customBrands = [], displayCount = 0, headingText = "Press About Our Firm:", customClass = '' }) => {
    // Prioritize customBrands if provided, otherwise use customLogos
    const logosToDisplay = customBrands.length > 0 ? customBrands : (customLogos.length > 0 ? customLogos : defaultFeaturedData);
    const logosToShow = displayCount > 0 ? logosToDisplay.slice(0, displayCount) : logosToDisplay;
  
    return (
        <div className={`featured-on-container ${customClass}`}>
          <h2>{headingText}</h2>
          <div className="logos">
              {logosToShow.map(({ id, logo, alt, link }) => (
                  <a key={id} href={link || "#"} target="_blank" rel="noopener noreferrer">
                      <img src={logo} alt={alt || "Logo"} className="logo" />
                  </a>
              ))}
          </div>
      </div>
  );
};

export default FeaturedOn;