import React, { useEffect } from 'react'

import './style.scss'

const L1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>L-1 Visa</h1>
            <p>The L-1 nonimmigrant classification enables a U.S. employer to transfer an executive or manager from one of its affiliated foreign offices to one of its offices in the United States. This classification also enables a foreign company which does not yet have an affiliated U.S. office to send an executive or manager to the United States with the purpose of establishing one.</p>

            <h2>L-1 Visa Overview</h2>
            <p>There are two types of L-1 Visa:</p>
            <ol>
                <li>The L-1A visa is for executives or managers of international companies. They allow an employee who is either in an executive role or a management-level role to transfer to the US operation. They can also come to the United States to set up a US operation.</li>
                <li>The L-1B visa is for “specialized employees” who have knowledge or skills that are critical to the company's success.</li>
            </ol>
            <p>In either case, the applicant must have worked for the company for at least one continuous year in the past three years.</p>

            <h2>Key Considerations for L-1 Visa Applications</h2>
            <p>The most important things to ensure with an L-1 visa application are to show that:</p>
            <ol>
                <li>the nexus of the company are either affiliates or subsidiaries of the international company, and that</li>
                <li>the employee is working in the right type of job.</li>
            </ol>

            <h2>Employer Requirements</h2>
            <ol>
                <li>Employer must have a “qualifying relationship” with a foreign company (parent, branch, subsidiary, affiliate), referred to as “qualifying organization”, and</li>
                <li>Currently be, or will be, doing business as an employer in the U.S. and at least one other country through a qualifying organization for the duration of the beneficiary’s stay in the U.S. as an L-1 visa holder.</li>
                <li>Employers seeking to establish a new office must show sufficient physical premises, meet employee requirements below, and intend the new office will support an executive and/or managerial position within a year.</li>
                <li>“Doing business” means provision of goods and/or services does not mean mere presence of an agent or office in the U.S.</li>
            </ol>

            <h2>Employee Requirements</h2>
            <ol>
                <li>Generally have worked for the organization abroad for one continuous year of the last three, and</li>
                <li>Be seeking to enter the U.S. to render services in an executive or managerial capacity.</li>
                <li>Period of stay - the L-1 is granted for an initial 3-year period, with two possible extensions of 2 years each, totaling a 7-year stay altogether (employees entering for a new office are allowed a 1-year stay).</li>
            </ol>

            <h2>Family of L-1 Workers</h2>
            <p>A spouse and any unmarried children under 21 may accompany the L-1 recipient under an L-2 visa (generally granted for the same period of stay). Spouses have no restriction regarding work.</p>

            <h2>Blanket Petitions</h2>
            <p>Certain organizations may establish the required intra-company relationship in advance, called a “Blanket” petition. It allows the employer to transfer with short notice without having to file individually with USCIS. Requirements beyond what is mentioned above:</p>
            <ol>
                <li>Have 3 or more domestic and foreign branches, subsidiaries, affiliates.</li>
                <li>Must have one of the following: at least 10 L-1 approvals in previous 12 months, U.S. subsidiaries with combined annual sales of $25 million, or a U.S. workforce of 1,000 employees.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1AD4foDHCq5aU1LZyKdkmqWSprlEP9EaDN8fD0lnazktKPtxobWXsi66fjJr4MIYr"></div>
            </div>
        </div>
    )
}
export default L1ContentArea