import React, { useEffect } from 'react';

import './style.scss';

const TLContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h1>Tax Law Services</h1>
                <p>Navigating the complexities of tax law can be daunting, but with our expert guidance, you can ensure your business and personal finances remain compliant and optimized. Our Tax Law services are designed to help you manage your tax obligations efficiently, reduce liabilities, and avoid potential legal issues.</p>
                
                <h2>Why Choose Us for Tax Law?</h2>
                <p>Tax laws are constantly evolving, and staying up-to-date can be a challenge for individuals and businesses alike. Our experienced tax attorneys are here to provide you with the latest legal insights, personalized strategies, and comprehensive support to meet your tax-related needs. Whether you’re a small business owner, a large corporation, or an individual taxpayer, we tailor our services to your specific situation.</p>

                <h3>Key Areas of Tax Law We Cover:</h3>
                <ol>
                    <li><strong>Tax Planning and Strategy</strong></li>
                    <p>Proactive tax planning is essential to minimizing liabilities and maximizing your financial benefits. We work closely with you to develop and implement effective tax strategies that align with your financial goals.</p>
                    
                    <li><strong>Tax Compliance and Reporting</strong></li>
                    <p>Ensuring compliance with federal, state, and local tax laws is critical to avoiding penalties and audits. Our team assists with accurate tax reporting, timely filings, and navigating complex tax regulations.</p>
                    
                    <li><strong>IRS and State Tax Disputes</strong></li>
                    <p>If you’re facing a tax dispute with the IRS or state tax authorities, our attorneys provide strong representation to protect your rights. We handle audits, negotiations, appeals, and litigation to resolve disputes favorably.</p>
                    
                    <li><strong>Business Taxation</strong></li>
                    <p>From corporate tax issues to small business concerns, we offer comprehensive tax services that help your business stay compliant and financially sound. Our expertise covers income tax, payroll tax, sales tax, and more.</p>
        
                    <li><strong>International Taxation</strong></li>
                    <p>For businesses and individuals with cross-border interests, understanding international tax laws is crucial. We provide guidance on foreign income, transfer pricing, and compliance with global tax regulations.</p>
        
                    <li><strong>Estate and Gift Tax Planning</strong></li>
                    <p>Effective estate and gift tax planning ensures that your wealth is preserved for future generations. We help you navigate complex estate tax laws and develop strategies to minimize tax liabilities on transfers of wealth.</p>
                </ol>

                <h4>Your Partner in Tax Law Success</h4>
                <p>Our Tax Law practice is committed to providing you with the knowledge and support needed to navigate the complexities of the tax system. We pride ourselves on delivering results-driven strategies that protect your interests and help you achieve your financial goals.</p>

                <h4>Experienced Tax Law Attorneys You Can Trust</h4>
                <p>With years of experience in tax law, our attorneys are well-equipped to handle even the most complex cases. We are dedicated to providing the highest level of legal representation, ensuring that your financial future is secure. From initial consultations to final resolutions, we stand by your side every step of the way.</p>

                <p>If you need expert tax law assistance, contact us today to learn how we can support your tax planning, compliance, and dispute resolution needs.</p>

                <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1AheaQtnMmPPJ8mu9X6b5tIEodhE4KShCtUQ0A1CAdSoRQUYVrAj3iGsjC3alIqUb"></div>
            </div>
        </div>
    );
};

export default TLContentArea;
