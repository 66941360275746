import React, { useEffect } from 'react';

import './style.scss';

const LitContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h1>Litigation Services</h1>
                <p>When legal disputes arise, having a strong, experienced advocate on your side is essential. Our litigation team is dedicated to protecting your rights and interests, whether you're facing a complex commercial dispute or a simple contractual disagreement. We provide comprehensive litigation services designed to achieve the best possible outcomes for our clients.</p>
                
                <h2>Why Choose Us for Litigation?</h2>
                <p>Litigation can be a daunting and stressful experience, but with our skilled attorneys, you can approach it with confidence. We combine deep legal knowledge with a strategic approach, ensuring that your case is handled with the utmost care and expertise. From pre-litigation counseling to trial representation, we are committed to guiding you through every step of the legal process.</p>

                <h3>Key Areas of Litigation We Cover:</h3>
                <ol>
                    <li><strong>Commercial Litigation</strong></li>
                    <p>Our attorneys represent businesses in a wide range of commercial disputes, including breach of contract, business torts, and partnership disputes. We work tirelessly to resolve conflicts efficiently, whether through negotiation, mediation, arbitration, or courtroom litigation.</p>

                    <li><strong>Civil Litigation</strong></li>
                    <p>We handle civil litigation cases across a broad spectrum, including personal injury, real estate disputes, and consumer protection cases. Our goal is to secure fair and just outcomes for our clients while minimizing the stress and disruption that litigation can cause.</p>

                    <li><strong>Employment Litigation</strong></li>
                    <p>Employment-related disputes can be particularly challenging. We represent both employers and employees in cases involving wrongful termination, discrimination, harassment, wage and hour disputes, and other employment law issues. Our team strives to achieve fair resolutions while protecting our clients' legal and financial interests.</p>

                    <li><strong>Intellectual Property Litigation</strong></li>
                    <p>Protecting your intellectual property is crucial to maintaining your competitive edge. We represent clients in disputes involving trademarks, copyrights, patents, and trade secrets, ensuring that your intellectual property rights are upheld in court.</p>

                    <li><strong>Real Estate Litigation</strong></li>
                    <p>Whether you're dealing with boundary disputes, landlord-tenant issues, or zoning conflicts, our attorneys are equipped to handle all aspects of real estate litigation. We work to resolve disputes efficiently and in a manner that preserves your property rights.</p>

                    <li><strong>Alternative Dispute Resolution</strong></li>
                    <p>Not all disputes need to end up in court. We are experienced in alternative dispute resolution (ADR) methods, such as mediation and arbitration, which can offer faster, less adversarial solutions to legal conflicts.</p>
                </ol>

                <h4>A Strategic Approach to Litigation</h4>
                <p>Our approach to litigation is both proactive and strategic. We carefully assess the strengths and weaknesses of each case, develop a tailored legal strategy, and vigorously advocate on behalf of our clients. We understand that litigation can be costly and time-consuming, so we focus on achieving efficient resolutions that align with your business or personal goals.</p>

                <h4>Experienced Representation You Can Trust</h4>
                <p>With years of experience in both state and federal courts, our litigation attorneys have the skills and knowledge necessary to handle even the most complex legal challenges. We are dedicated to providing our clients with the highest level of representation, ensuring that their rights are protected and their voices are heard.</p>

                <p>If you're facing a legal dispute, don't navigate it alone. Contact us today to learn how our litigation services can help you achieve a favorable outcome.</p>

                <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2XDfwOVaJHsekGHuzzZOpdk2a3fvYtCvspj1urMNngUfFaXXiGLUFZyjFXvdsOf1F"></div>
            </div>
        </div>
    );
};

export default LitContentArea;
