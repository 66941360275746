import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { scrollToSection } from '../SmoothScrolling';
import logo from '../../images/logo/logo.png';
import './style.scss';

const HeaderBottom = (props) => {
    const [isResponsive, setIsResponsive] = useState(false);
    const responsiveHandler = () => {
        setIsResponsive(prevState => !prevState);
    }

    const responsiveTriggerClass = isResponsive ? "responsiveTrigger active" : "responsiveTrigger";

    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerBottomArea">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={logo} alt="Logo" />
                                </NavLink>
                            </div>
                        </div>
                        <div className={isResponsive ? "col-12 responsiveWrapper active" : "col-6 d-none d-md-block responsiveWrapper"}>
                            <ul className="mainMenuWrap">
                                <li>
                                    <NavLink to='/about'>About</NavLink>
                                    {!isResponsive && (
                                        <div className="megaMenuContent">
                                            <div className="contentBlock">
                                                <ul>
                                                    <li>
                                                        <NavLink to='/jon-velie#jon-velie-section'>Jon Velie</NavLink>
                                                    </li>
                                                    <li>
                                                        <a href="/yesh#yesh-section" onClick={(e) => scrollToSection(e, 'yesh-section')}>Yesh Vasamsetty</a>
                                                    </li>
                                                    <li>
                                                        <a href="/kevin-vance#kevin-vance-section" onClick={(e) => scrollToSection(e, 'kevin-vance-section')}>Kevin Vance</a>
                                                    </li>
                                                    <li><NavLink to='/ashley-morin'>Ashley Morin</NavLink></li>
                                                    <li className="desktop-attorneys-menu-item">
                                                        <NavLink to='/attorneys'>Attorneys</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </li>

                                {/* Mobile Top-level Menu Item for Attorneys */}
                                <li className="mobile-attorneys-menu-item">
                                    {isResponsive && (
                                        <NavLink to='/attorneys'>Attorneys</NavLink>
                                    )}
                                </li>

                                <li>
                                    <a href="#areas-of-practice" onClick={(e) => scrollToSection(e, 'section1')}>Practice Areas</a>
                                    {!isResponsive && (
                                        <div className="megaMenuContent">
                                            <div className="contentBlock">
                                                <ul>
                                                    <li><NavLink to='/work-visa'>Business Immigration</NavLink></li>
                                                    <li><NavLink to='/corporate-law'>Corporate Law</NavLink></li>
                                                    <li><NavLink to='/litigation'>Litigation</NavLink></li>
                                                    <li><NavLink to='/family-law'>Family Law</NavLink></li>
                                                    <li className="tax-law-item"><NavLink to='/tax-law'>Tax Law</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </li>

                                <li><NavLink to='/work-visa'>Work Visas</NavLink>
                                    {!isResponsive && (
                                        <div className="megaMenuContent">
                                            <div className="contentBlock">
                                                <h4>Temporary</h4>
                                                <ul>
                                                    <li><NavLink to='/b1-visa'>B-1</NavLink></li>
                                                    <li><NavLink to='/e1-visa'>E-1</NavLink></li>
                                                    <li><NavLink to='/e2-visa'>E-2</NavLink></li>
                                                    <li><NavLink to='/e3-visa'>E-3</NavLink></li>
                                                    <li><NavLink to='/h3-visa'>H-3</NavLink></li>
                                                    <li><NavLink to='/j1-visa'>J-1</NavLink></li>
                                                    <li><NavLink to='/l1-visa'>L-1</NavLink></li>
                                                    <li><NavLink to='/o1-visa'>O-1</NavLink></li>
                                                    <li><NavLink to='/p1-visa'>P-1</NavLink></li>
                                                    <li><NavLink to='/p3-visa'>P-3</NavLink></li>
                                                    <li><NavLink to='/r1-visa'>R-1</NavLink></li>
                                                    <li><NavLink to='/tn-visa'>TN</NavLink></li>
                                                    <li><NavLink to='/h1b-visa'>H-1B</NavLink></li>
                                                    <li><NavLink to='/i-visa'>I</NavLink></li>
                                                </ul>
                                            </div>
                                            <div className="contentBlock">
                                                <h4>Permanent</h4>
                                                <ul>
                                                    <li><NavLink to='/eb1-visa'>EB-1A</NavLink></li>
                                                    <li><NavLink to='/eb1b-visa'>EB-1B</NavLink></li>
                                                    <li><NavLink to='/eb1c-visa'>EB-1C</NavLink></li>
                                                    <li><NavLink to='/eb5-visa'>EB-5</NavLink></li>
                                                    <li><NavLink to='/iep'>IEP</NavLink></li>
                                                    <li><NavLink to='/eb2-visa'>EB-2 & EB-3</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </li>

                                <li><NavLink to='/family-visa'>Family Visas</NavLink>
                                    {!isResponsive && (
                                        <div className="megaMenuContent">
                                            <div className="contentBlock">
                                                <h4>Temporary</h4>
                                                <ul>
                                                    <li><NavLink to='/k1-visa'>K-1</NavLink></li>
                                                    <li><NavLink to='/k2-visa'>K-2</NavLink></li>
                                                    <li><NavLink to='/k3-visa'>K-3</NavLink></li>
                                                    <li><NavLink to='/k4-visa'>K-4</NavLink></li>
                                                </ul>
                                                <h4>Permanent</h4>
                                                <ul>
                                                    <li><NavLink to='/spouse-visa'>Spouse Green Card</NavLink></li>
                                                    <li><NavLink to='/family-preference'>Family Preference</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </li>

                                <li><NavLink to='/contact'>Contact</NavLink></li>
                            </ul>
                        </div>
                        {!isResponsive && (
                            <React.Fragment>
                                <div className="col-lg-2 col-md-3 col-sm-4 d-none d-md-block d-flex">
                                    <div className="btnStyle text-right">
                                        <a href="https://calendly.com/onlinevisas/strategy-session-by-velie-global-law-firm-199" target='_blank' rel='noopener noreferrer'>Strategy Session</a>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-4 d-none d-md-block d-flex">
                                    <div className="btnStyle text-right">
                                        <a href="https://app.visas.ai/login" target='_blank' rel='noopener noreferrer'>Client Portal</a>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        <div className="col-sm-4 col-xs-4">
                            <div className={responsiveTriggerClass} onClick={responsiveHandler}>
                                <span className="first"></span>
                                <span className="second"></span>
                                <span className="third"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderBottom;
