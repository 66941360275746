import React from 'react';
import './style.scss';

const ImmigrationServices = ({ className, services }) => {
  return (
    <div className={`immigrationServiceArea ${className}`}>
      <h2 className="title">Immigration Services</h2>
      <p className="tagline">Immigration laws are complex, and they're changing every day. We provide clarity, expertise and unmatched service.</p>
      <div className="row">
        {services.map((category, index) => (
          <div key={index} className="col-md-4">
            <h3 className="category-title">{category.title}</h3>
            <ul className="service-list">
              {category.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button className="btn btn-primary">Strategy Session</button>
    </div>
  );
};

export default ImmigrationServices;
