import React, { useEffect } from 'react'

import './style.scss'

const TNContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>TN Visa</h1>
            <p>The TN visa allows Canadian and Mexican citizens to be admitted to the U.S. to engage in business activities in designated fields or categories.</p>
            <p>The full title of the visa category is “Professionals Under the North American Free Trade Agreement.” It is available only to citizens of Canada and Mexico under the terms of the <a href='https://ustr.gov/trade-agreements/free-trade-agreements/north-american-free-trade-agreement-nafta' target='blank' rel='noopener noreferrer'>North American Free Trade Agreement</a> (NAFTA).</p>

            <h2>TN/NAFTA Visa Eligibility</h2>
            <p>You are eligible for a <a href='https://onlinevisas.com/tn-visa/'>TN visa</a> if…</p>
            <ol>
                <li>You are a citizen of Mexico or Canada working in a professional occupation on the <a href="https://www.albany.edu/hr/assets/occupations.pdf" target="_blank" rel='noopener noreferrer'> NAFTA list</a> (list of professions:).</li>
                <li>You possess the specific criteria for that profession.</li>
                <li>Your prospective position requires someone in that profession.</li>
                <li>You will work for a U.S. employer.</li>
            </ol>

            <h2>TN Visa Terms</h2>
            <ol>
                <li><strong>Travel</strong> - TN visa holders may travel in and out of the U.S. while the visa is valid and the intended professional activities haven't changed.</li>
                <li><strong>Length of stay</strong> - Is now 3 years (was 1 year initially).</li>
                <li><strong>Extensions</strong> - The TN visa may be extended indefinitely in 1-year increments. You must demonstrate a foreign residence and intention of returning.</li>
                <li><strong>Family / dependents</strong> - you may bring spouse and unmarried children under 21 with you under a TD visa.</li>
                <li><strong>Work for dependents</strong> - dependent TD visa holders are not allowed to work – they must qualify for another visa type in order to work.</li>
                <li><strong>Education for dependents</strong> - TD visa holders may attend school.</li>
            </ol>

            <h2>TN Visa Application Locations</h2>
            <p><strong>Canadians</strong> - can apply at a U.S. consulate, the border, or port of entry.</p>
            <p><strong>Mexicans</strong> - must apply at a U.S. consulate abroad.</p>

            <h2>TD Visa for Dependents of TN Visa Holders</h2>
            <p>Dependents of TN Visa holders (i.e., spouse or unmarried children under 21 years of age) may apply for the TD dependent's visa. Dependents may accompany the visa holder into the U.S. or may join them at a later date. Unlike the primary TN visa holder, dependents are not required to be citizens of Canada or Mexico.</p>
            <p>You will need to be able to demonstrate the relationship, usually with an accompanying marriage certificate or birth certificate.</p>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2ZayGbT6GfYH9hF43UQpFjIR3YRBgbdo2EAmhYXpkNMFQQh0LoMbbmBNkod5PwZzR"></div>
            </div>
    </div>
    )
}
export default TNContentArea