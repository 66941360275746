import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'
// import 'react-modal-video/scss/modal-video.scss'
// import ModalVideo from 'react-modal-video'

const About = ({ subTitle, title, className, paragraphs, images, orderLast, showButton, showImage }) => {
    // const [video, setVideo] = useState(false)
    const contentColumnClass = showImage ? 'col-lg-6' : 'col-lg-12';
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    {showImage && (
                    <div className={orderLast ? `${orderLast} col col-lg-6`:'col col-lg-6'}>
                        <div className="aboutImageWrap">
                            <img src={images} alt="" />
                        </div>
                    </div>
                    )}                    
                    <div className={contentColumnClass}>
                        <div className="aboutContent">
                            {subTitle && <span>{subTitle}</span>}
                            <h2>{title}</h2>
                            {paragraphs.map((paragraph, i) => (
                                <p key={i}>{paragraph.text}</p>
                            ))}
                            {showButton && (
                            <div className="btnStyle">
                                <Link to='/about'>More About Us..</Link>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About