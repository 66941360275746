// image
import portfolio1 from '../../images/studies/alex-lin-petition.jpg'
import portfolio2 from '../../images/studies/dr-luc-montagnier-petition.jpg'
import portfolio3 from '../../images/studies/craig-mcfarland-petition.jpg'
import portfolio4 from '../../images/studies/brad-sugar-petition.jpg'
import portfolio5 from '../../images/studies/tenco-management.jpg'
import portfolio6 from '../../images/studies/rosalie-thomass-petition.jpg'
import portfolio7 from '../../images/studies/mr-ori-levi.jpg'
import portfolio8 from '../../images/studies/james-ross-young.jpg'
import portfolio9 from '../../images/studies/visgh-kumar.jpg'
import portfolio10 from '../../images/studies/ben-graham.jpg'
import portfolio11 from '../../images/studies/ivan-ivankov.jpg'
import portfolio12 from '../../images/studies/tyson-foods.jpg'
import portfolio13 from '../../images/studies/the-beast.jpg'
import portfolio14 from '../../images/studies/petra-kvitova.jpg'
import portfolio15 from '../../images/studies/jennifer-snow-lee.jpg'
import portfolio16 from '../../images/studies/juerg-leuthold.jpg'
import portfolio17 from '../../images/studies/hunter-point-shipyard.jpg'
import portfolio18 from '../../images/studies/souray-petition.jpg'
import portfolio19 from '../../images/studies/michael-sandoval.jpg'
import portfolio20 from '../../images/studies/hid-global.jpg'
import portfolio21 from '../../images/studies/wailese-serevi.jpg'
import portfolio22 from '../../images/studies/georgia-bremner.jpg'
import portfolio23 from '../../images/studies/quality-inn.jpg'
import portfolio24 from '../../images/studies/genhong-lin.jpg'
import portfolio25 from '../../images/studies/hasmukhlal-patel.jpg'

const PortfolioItems = [
    { id: 1, images: portfolio1, title: 'Alex Len P-1', subtitle: 'NBA Player' },
    { id: 2, images: portfolio2, title: 'Dr. Luc Montagnier EB-1', subtitle: 'World Class Scientist' },
    { id: 3, images: portfolio3, title: 'Craig Mcfarlane 0-1', subtitle: 'America\'s Cup SNC Coach' },
    { id: 4, images: portfolio4, title: 'Brad Sugars L-1/EB-3', subtitle: 'Business Coach' },
    { id: 5, images: portfolio5, title: 'Robert Wright E-2', subtitle: 'Corporate Client' },
    { id: 6, images: portfolio6, title: 'Rosalie Thomass O-1B', subtitle: 'Actress' },
    { id: 7, images: portfolio7, title: 'Ori Levy L-1A', subtitle: 'Diamond Consultant' },
    { id: 8, images: portfolio8, title: 'Ross Young O-1/EB-1', subtitle: 'USA Rugby CEO' },
    { id: 9, images: portfolio9, title: 'Vish Kumar EB-1', subtitle: 'CEO Ego.live & AI Dev; Facebook, EY, IBM' },
    { id: 10, images: portfolio10, title: 'Ben Graham O-1', subtitle: 'NFL Superbowl Champion' },
    { id: 11, images: portfolio11, title: 'Ivan Ivankov EB-1', subtitle: 'Olympic & World Champion Gymnast' },
    { id: 12, images: portfolio12, title: 'Tyson Foods EB-1', subtitle: 'Corporate Client' },
    { id: 13, images: portfolio13, title: 'Tendai Mtawarira P-1', subtitle: 'World Cup Champion - MLR' },
    { id: 14, images: portfolio14, title: 'Petra Kvitova P-1', subtitle: 'Wimbledon Champion, #2 In The World' },
    { id: 15, images: portfolio15, title: 'Jennifer Snow Lee O-1', subtitle: 'Olympic Gold Medalist' },
    { id: 16, images: portfolio16, title: 'Juerg Leuthold O-1', subtitle: 'Corporate Client Bell Labs' },
    { id: 17, images: portfolio17, title: 'Hunter Point Shipyard EB-5', subtitle: 'Corporate Client' },
    { id: 18, images: portfolio18, title: 'Sheldon Souray', subtitle: 'NHL Player' },
    { id: 19, images: portfolio19, title: 'Michael Sandoval', subtitle: 'Sandoval Family Foundation, Inc.' },
    { id: 20, images: portfolio20, title: 'Dasong Wang', subtitle: 'HID Global, Inc.' },
    { id: 21, images: portfolio21, title: 'Waisale Serevi', subtitle: 'Sevens World Champion' },
    { id: 22, images: portfolio22, title: 'Georgie Bremner', subtitle: 'Ski Instructor' },
    { id: 23, images: portfolio23, title: 'Maneshkumar Patel', subtitle: 'Quality Inn & Suites' },
    { id: 24, images: portfolio24, title: 'Genhong Lin', subtitle: 'Songli Garment' },
    { id: 25, images: portfolio25, title: 'Hasmukhlal Patel', subtitle: 'Best Western & Suites' },
    
]

  export default PortfolioItems;