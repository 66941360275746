import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const HaitiTPSBlogContentArea = ({ className }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
            <h2>Extension and Redesignation of Haiti for TPS Announced</h2>
            <p>On June 28, 2024, Secretary of Homeland Security Alejandro N. Mayorkas announced the extension and redesignation of Haiti for Temporary Protected Status (TPS) for 18 months, from August 4, 2024, to February 3, 2026. This decision was made due to the extraordinary and temporary conditions in Haiti, as detailed in the corresponding Federal Register Notice, which provides information on how both new and current beneficiaries can register for TPS under this extension and redesignation.</p>            

            <h3>Reasons for Extension and Redesignation</h3>
            <p>After consulting with interagency partners, Secretary Mayorkas determined on June 3, 2024, that the extension and redesignation were warranted due to ongoing conditions in Haiti. These conditions include violence, insecurity, limited access to safety, healthcare, food, and water. Additionally, Haiti faces frequent natural disasters such as flooding, mudslides, storms, and earthquakes, which exacerbate the humanitarian needs of its population.</p>

            <p>“We are providing this humanitarian relief to Haitians already present in the United States given the conditions that existed in their home country as of June 3, 2024,” said Secretary Mayorkas. “In doing so, we are realizing the core objective of the TPS law and our obligation to fulfill it.”</p>

            <h3>Eligibility and Application Process</h3>
            <p>The redesignation of Haiti for TPS allows an estimated 309,000 additional Haitian nationals (or individuals who last habitually resided in Haiti) to file initial applications for TPS, provided they meet eligibility requirements and established residence in the United States on or before June 3, 2024, and have continuously resided in the U.S. since then. Eligible individuals who do not currently have TPS can submit an initial Form I-821, Application for Temporary Protected Status, from July 1, 2024, through February 3, 2026.</p>
            
            <p>Applicants may also apply for TPS-related Employment Authorization Documents (EAD) and travel authorization. An <Link to="uscis-policy-update">EAD</Link> can be requested by submitting a completed Form I-765, Application for Employment Authorization, along with Form I-821 or separately later. Haitians who were not residing in the United States on or before June 3, 2024, are not eligible for TPS and may face removal to Haiti if they do not establish a legal basis to stay.</p>

            <h3>Maintaining TPS Status</h3>
            <p>Current TPS beneficiaries from Haiti can retain their status through February 3, 2026, if they continue to meet eligibility requirements. They must re-register during the 60-day re-registration period from July 1, 2024, through August 30, 2024, to maintain their TPS and employment authorization. Re-registration is only available to those who previously registered for and were granted TPS under Haiti's initial designation.</p>
            
            <p>To accommodate potential delays, DHS is automatically extending the validity of certain EADs previously issued under Haiti's TPS designation through August 3, 2025. Current EAD holders must re-register for TPS and file Form I-765 to obtain an EAD valid after August 3, 2025. USCIS will issue new EADs valid through February 3, 2026, upon approval of the newly filed Form I-765.</p>
            
            <p>USCIS will continue processing pending applications filed under previous TPS designations for Haiti. Individuals with pending Form I-821 or Form I-765 applications as of July 1, 2024, do not need to reapply. Approved applications will grant TPS and EAD validity through February 3, 2026.</p>

            <h3>Duration of Stay and Extensions</h3>
            <p>The updated USCIS Policy Manual offers more comprehensive guidance on the duration of stay for exchange visitors and the possibility of visa extensions. It outlines the circumstances under which they grant an extension, the required supporting documentation, and the maximum permissible stay for each exchange program category. It provides greater clarity for exchange visitors and sponsors, enabling them to plan and manage their programs more effectively.</p>

            <h3>Enforcement and Safety Measures</h3>
            <p>This extension and redesignation do not apply to individuals who were not in the United States on or before June 3, 2024. DHS emphasizes that consequences will continue for those attempting to enter the U.S. unlawfully. Since the Securing the Border Presidential Proclamation and Interim Final Rule issued in early June, over 24,000 noncitizens have been removed or returned to more than 20 countries. DHS will continue to enforce U.S. laws and policies throughout the Florida Straits, the Caribbean region, and the southwest border. The U.S. policy remains to return noncitizens who do not establish a legal basis to remain in the United States.</p>
            
            <p>For those navigating the complexities of U.S. immigration, we offer expert guidance and support. Secure your slot today and book your strategy session to ensure a smooth immigration process. Trust OnlineVisas to help you achieve your immigration goals with confidence and ease.</p>
            <br></br>
            </div>
        </div>
    )
}
export default HaitiTPSBlogContentArea