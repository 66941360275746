import React, { useEffect } from 'react'

import './style.scss'

const CLContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>Corporate Law Services</h1>
            <p>In today's complex business environment, navigating corporate law requires expert guidance. Our firm offers comprehensive legal services to help businesses of all sizes manage their legal obligations, protect their interests, and achieve their goals.</p>
            
            <h2>Why Choose Us for Corporate Law?</h2>
            <p>We understand that every business is unique, with its own set of challenges and opportunities. Our experienced corporate law attorneys provide personalized legal solutions tailored to the specific needs of your business. Whether you're a startup seeking to establish your presence or a well-established company looking to expand, we are here to support you at every stage of your business journey.</p>

            <h3>Key Areas of Corporate Law We Cover:</h3>
            <ol>
                <li><strong>Business Formation and Structuring</strong></li><p>Choosing the right business structure is crucial to your company's success. We assist with forming corporations, partnerships, LLCs, and other entities, ensuring compliance with all relevant legal requirements.</p>
                
                <li><strong>Mergers and Acquisitions</strong></li><p>We offer strategic advice and legal support throughout the M&A process, from initial negotiations to finalizing the deal. Our goal is to help you achieve a smooth and successful transaction.</p>
                
                <li><strong>Corporate Governance</strong></li><p>Effective corporate governance is vital for maintaining investor confidence and regulatory compliance. We provide counsel on board responsibilities, shareholder rights, and corporate policies.</p>
                
                <li><strong>Contracts and Agreements</strong></li>
                <p>Our attorneys draft, review, and negotiate all types of business contracts, including employment agreements, non-compete clauses, partnership agreements, and more, to safeguard your business interests.</p>
    
                <li><strong>Compliance and Risk Management</strong></li>
                <p>Staying compliant with federal, state, and local laws is essential to avoiding costly legal issues. We help businesses identify potential risks and implement strategies to mitigate them.</p>
    
                <li><strong>Dispute Resolution and Litigation</strong></li>
                <p>When disputes arise, our team is prepared to represent your business in negotiations, mediations, arbitrations, and court proceedings. We aim to resolve conflicts efficiently and in your favor.</p>
            </ol>

            <h4>Dedicated Support for Your Business Success</h4>
            <p>Our corporate law practice is dedicated to providing you with the legal foundation and support needed to thrive in today's competitive market. We work closely with our clients to ensure they are informed and confident in their legal decisions.</p>

            <p>If you are looking for a trusted partner in corporate law, contact us today to learn how we can assist with your business needs.</p>
            <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1ADB5eRz8axnyGQXxLTf2XTk7aKDx0JXc4RgNkyaPG3ZFpA0OqNMmtSCXYGTKTmq7"></div>
            </div>
    </div>
    )
}
export default CLContentArea