import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './style.scss'

// images
import post1 from '../../images/case-studies-details/j1-visa-exchange.jpg'
import post2 from '../../images/case-studies-details/immigration-figurines.jpg'
import post3 from '../../images/case-studies-details/uscis-logos.jpg'
import post4 from '../../images/case-studies-details/h2b-ag-visa.jpg'
import post5 from '../../images/case-studies-details/eb5-rate-hike-small.png'
import post6 from '../../images/case-studies-details/fy25-h1b-cap-small.jpg'
import post7 from '../../images/case-studies-details/uscis-stem-update-small.jpg'
import post8 from '../../images/case-studies-details/haiti-tps-update-75x75.jpeg'

const RecentPosts = ({ className }) => {
    const [posts] = useState([
        {
            image: post1,
            title: 'Navigating the J-1 Visa Journey',
            route: '/j1-journey',
        },
        {
            image: post2,
            title: 'Navigating the U.S. 10-Year Multiple Visas',
            route: '/b1-journey',
        },
        {
            image: post3,
            title: 'USCIS Policy Manual Update',
            route: '/uscis-policy-update',
        },
        {
            image: post4,
            title: 'The H-2A Visa: Empowering Agricultural',
            route: '/h2a-agriculture',
        },
        {
            image: post5,
            title: 'EB-5 Increased USCIS I-526E Filing Fees',
            route: '/eb5-rate-hikes',
        },
        {
            image: post6,
            title: 'Important Updates for FY 2025 H-1B Cap',
            route: '/h1b-cap-update',
        },
        {
            image: post7,
            title: 'USCIS Updates Spark Surge in STEM',
            route: '/uscis-stem-update',
        },
        {
            image: post8,
            title: 'Redesignates Haiti for TPS',
            route: '/haiti-tps-update',
        }
    ]);

    const [filteredPosts, setFilteredPosts] = useState([]);
    const location = useLocation();

    useEffect(() => {
        // Filter posts that do not have a route matching the current URL path
        const updatedFilteredPosts = posts.filter(post => !location.pathname.includes(post.route));
        setFilteredPosts(updatedFilteredPosts);
    }, [location, posts]); // Add location and posts as dependencies so it re-runs when they change

    return (
        <div className={className}>
            <h3>Our Take In Immigration</h3>
            <ul>
                {filteredPosts.map((post, index) => (
                    <li key={index} className="postItem">
                        <div className="postImg">
                            <img src={post.image} alt={post.title} />
                        </div>
                        <div className="postContent">
                            <Link to={post.route}>{post.title}</Link>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentPosts;