import React, { useEffect } from 'react';

import './style.scss'

const E2ContentArea = ({ className }) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentWrap">
            <h1>E-2 Visa</h1>
            <p>The E-2 Visa is the “Treaty Investor” visa that is available for 30+ countries around the world which have treaty agreements with the United States. Individuals from these countries can open up offices or practices in the United States, but they need to invest a substantial amount of money in the country in order to do so.</p>

            <h2>Key Considerations for E-2 Visa</h2>
            <p>As a guideline, the basic amount of money invested, which the US Consulates will consider sufficient is around US$100,000. USCIS will sometimes accept around US$50,000. We like to use the former amount ($100k) to be safe. In any case, that money can be invested into a company, or used as investment to create a new company.</p>

            <p><strong>Important Note:</strong> You cannot create a job solely for the individual E-2 visa applicant, so there must be other people employed by the this company. We believe that the minimum number of employees is between 3 and 5. The business must have its own facility or premises, so the E-2 visa holder cannot simply work from their house.</p>

            <p>The E-2 can be a fantastic visa! Although it is a non-immigrant visa, meaning it is a temporary visa, it can be extended as long as the company is viable (i.e. it continues to produce goods or services). The E-2 also allows the visa holder to bring their spouse and family into the United States. The spouse may also work in the US under a EAD card (“Employment Authorization Document”) and also other people who are essential to the company (although they must come from the same country as the E-2 Visa applicant).</p>

            <h2>E-2 Visa Requirements</h2>
            <ol>
                <li>Applicants must be nationals of a country that the U.S. has executed and maintains a treaty (not a requirement for family members).</li>
                <li>Must have either invested or be in the act of investing a “substantial” amount of capital in a U.S. business. Substantial is defined as large in relation to total cost of establishing or purchasing the enterprise, or sufficient to ensure treaty investor's financial commitment to success and likelihood trader will develop the enterprise. The lower the cost of the enterprise, the higher the proportional investment must be to be considered substantial.</li>
                <li>Investment means the treaty investor is placing capital at risk of loss, including funds or other assets. Investors must show funds have not been obtained directly or indirectly from criminal activity.</li>
                <li>Be seeking to enter the U.S. solely to develop and direct the investment enterprise. This is demonstrated through at least 50% ownership or possession of operational control via a managerial position or other corporate device.</li>
                <li>Investment may not be “marginal.” This is an enterprise that doesn't have the present or future capacity to generate income to provide minimal living for the treaty investor and family. Time frame to show capacity is 5 years.</li>
            </ol>

            <h3>Requirements of E-2 “Employee of Treaty Investor”</h3>
            <ol>
                <li>Employee must be the same nationality of the principal employer (who must be national of the treaty country).</li>
                <li>Meet definition of “employee” under relevant law.</li>
                <li>Be engaging in supervisory or executive capacity, or have relevant special qualifications.</li>
            </ol>

            <h2>Additional E-2 Visa Terms</h2>
            <ol>
                <li>Period of Stay - up to two years. Extensions may be granted in increments of two years, with no maximum number of extensions limit.</li>
                <li>Family members - includes spouses or children under 21 years. Nationality need not be the same. Spouses may be approved for work.</li>
                <li>Travel abroad - travel is permitted for the treaty trader or employee, and an automatic 2-year extension granted upon re-admission. This new readmission period will not be granted automatically to family members, who should carefully monitor the period of stay and apply for extension to remain in the U.S. lawfully.</li>
            </ol>
            </div>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2XjyjG0PGxHQ5gftX1bHyt4jkUYrUNFLif6saE3wdYtXd9C4wrU1Hz67XerEP06H1"></div>
        </div>
    );
};

export default E2ContentArea