import React, { useEffect } from 'react'

import './style.scss'

const K3ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>K-3 Visa</h1>
            <p>The K-3 Spouse Visa is for a spouse of a U.S. citizen to enter the U.S. and await availability of an immigrant visa.</p>
            <p>A K-3 visa is relevant when marriage has occurred outside the U.S. compared to the <a href='https://onlinevisas.com/k-visas/'>K-1 visa</a> where a non-resident fiancé visits the U.S. with the intention to marry a U.S. citizen inside the U.S.</p>
                
            <h2>Qualifications for K-3 Visa</h2>
            <p>K-3 qualifications include:</p>
            <ol>
                <li>The foreign national is the legal spouse of a U.S. citizen.</li>
                <li>The non-resident spouse resides outside the U.S.</li>
                <li>A U.S. citizen spouse must have filed a petition for the non-resident spouse which is pending.</li>
            </ol>
                
            <h2>K-3 Visa Additional Requirements & Terms</h2>
            <p>Children - children may accompany under the <strong>K-4 Child Visa</strong> if under 21 and unmarried.</p>
            <p>Travel - K-3 visa holders may travel outside the U.S. and re-enter.</p>
            <p>Employment - may apply for a work permit from the USCIS by way of an Employment Authorization Document (EAD). You cannot begin work until the EAD has been granted.</p>
            <p>Study - you may study under a K-3 visa.</p>
            <p>Length of stay - A K-3 visa is valid for 2 years. An extension may be granted if applied for 120 days prior to expiration. Extensions are issued in 2 year intervals.</p>
            <p>Processing time - varies but typically is 3-5 months at the USCIS with an additional 2-3 months at Consulates.</p>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/31jwXP5evbWNTa5Hla4SRadgXvq93cDhdN7F2FuFKUtDEhOrL6LAykeh1jCaEUy9Z"></div>
            </div>
    </div>
    )
}
export default K3ContentArea