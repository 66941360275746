import React, { useEffect } from 'react'

import './style.scss'

const EB1CContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>EB-1C Visa for Multinational Executives or Managers</h1>
            <p>The EB-1C is a first-preference employment-based permanent residency suitable for multinational executives or managers. While the employing company must be multinational, it doesn't necessarily have to be large. The EB-1C visa can also facilitate immediate permanent residency for the holder's spouse and any unmarried children under 21.</p>

            <h2>EB-1C Visa Overview</h2>
            <p>The EB-1C visa is desirable due to current priority dates, meaning lower waiting times. However, qualification is challenging, and applications are closely scrutinized, necessitating an experienced immigration attorney. Unlike other visas, the EB-1C does not require going through the PERM labor certification process.</p>

            <h2>Eligibility Criteria for EB-1C Visa</h2>
            <ol>
                <li>Must have been employed outside the U.S. for 1 of the preceding 3 years by a firm or corporation in a managerial or executive capacity.</li>
                <li>The applicant must be seeking to continue service to the same organization in the U.S., applicable whether seeking entry to the U.S. or already present in the country.</li>
                <li>Cannot self-petition; the employer must file the application.</li>
                <li>The petitioning employer must be a U.S. employer that has been doing business for at least 1 year.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1El4d0g25dFg3KoFZY52mHh8ET0wELPf5zR9v2n37c3JlkLMbkTVJJehTxofsHmtd"></div>    
            </div>
        </div>
    )
}
export default EB1CContentArea