import React, { useEffect } from 'react'

import './style.scss'

const K1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>K-1 Visa</h1>
            <p>The <a href='https://onlinevisas.com/k-visas/'>K-1</a> Visa (Fiancé Marriage Visa) is for the fiancé of a U.S. citizen entering the U.S. to marry his/her fiancé.</p>
            <p>Benefits of the K-1 fiancé include the ability to apply for employment and the ability of children to accompany the K-1 holder to the U.S. under a K-2 dependent visa (if they are named in the K-1 visa petition).</p>

            <h2>K-1 Visa Requirements</h2>
            <ol>
                <li>The U.S. citizen and non-resident fiancé are legally eligible to marry under the laws <strong>in both countries</strong>.</li>
                <li>The marriage will occur within ninety days of entering the U.S.</li>
                <li>You intend to enter the U.S. solely to marry the U.S. citizen.</li>
                <li>The marrying parties have met at least once within two years before the filing of the K-1 visa. (This can be waived in certain circumstances.)</li>
            </ol>

            <h2>Change of Status: Green Card / Permanent Residency</h2>
            <p>Once the marriage takes place, you must apply to adjust your status to permanent resident.</p>
            <p>If your adjustment of status to permanent residency occurs within two years of marriage, you would initially receive a two year <strong>conditional residence</strong> and need to file additional paperwork later to remove conditions.</p>

            <h2>K-1 Visa Additional Requirements & Terms</h2>
            <ol>
                <li><strong>Financial responsibility</strong> - The U.S. citizen is completely responsible for the K-1 fiancé financially.</li>
                <li><strong>Reapplication for K-1</strong> - If you do not marry for any reason and the fiancé departs the U.S. as required within the required ninety day period, the U.S. citizen will not be precluded from re-applying for the K-1 visa with the same or another fiancé.</li>
                <li><strong>Marriage in the U.S. versus outside the U.S.</strong> - If the marriage will occur in the U.S., the fiancé requires a K-1 Fiancé visa. If the marriage occurs outside the U.S., the spouse will require an immigrant visa to come to the U.S. At that time, an application for permanent residency can be made.</li>
                <li><strong>Applying for a K-1 visa while Fiancé is in the U.S.</strong> - Is not allowed. If the fiancé is in the U.S. on another visa (or illegally), you may not apply for the K-1 while here. Application must be made at an Embassy or Consulate outside the U.S.</li>
                <li><strong>Employment</strong> - A K-1 fiancé may work after receiving permission from the USCIS by way of an Employment Authorization Document (EAD) which can only be applied for after arrival in the U.S.</li>
                <li><strong>Length of stay</strong> - A K-1 visa is valid for 6 months. You must enter the U.S. before the expiration date on the K-1 visa. You have 90 days to get married or must return to your home country. There is no legal extension.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/31jwXP5evbWNTa5Hla4SRadgXvq93cDhdN7F2FuFKUtDEhOrL6LAykeh1jCaEUy9Z"></div>
            </div>
    </div>
    )
}
export default K1ContentArea