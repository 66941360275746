import React, { useEffect } from 'react'

import './style.scss'

const EB1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>EB-1A “Extraordinary Ability Green Card”</h1>
                <p>The EB-1A Visa, also known as the Employment-Based “Extraordinary” Ability Permanent Residence/Green Card (First Preference), is designed for individuals with extraordinary ability in the sciences, arts, education, business, or athletics.</p>

                <h2>EB-1A Visa Overview</h2>
                <p>The EB-1A is highly sought after due to current priority dates, meaning little wait time. However, the qualification criteria are stringent and applications undergo rigorous scrutiny.</p>

                <h2>EB-1A Visa Eligibility Criteria</h2>
                <p>To qualify for an EB-1A, candidates must demonstrate extraordinary ability through sustained acclaim and meet specific USCIS criteria.</p>

                <h3>Extraordinary Ability</h3>
                <p>Candidates must demonstrate extraordinary ability in their field and meet 3 of 10 USCIS criteria or provide evidence of a significant, one-time achievement.</p>

                <h3>Outstanding Professors or Researchers</h3>
                <p>Applicants must be recognized internationally in their academic field, have 3 years of experience, and seek a tenure-track position or comparable research position in the U.S.</p>

                <h3>Multinational Executive or Manager</h3>
                <p>Applicants must have been employed outside the U.S. in a managerial or executive capacity for 1 of the last 3 years and seek to continue service in the U.S. with the same employer.</p>
                <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2TzpOWEeNe3CEILNO90FVXXNoQ44ZsTpWZqzLL1v8NsQPI6N1hqG6srFAaxnH7j1h"></div>
            </div>
    </div>
    )
}
export default EB1ContentArea