import React, { useEffect } from 'react';

import './style.scss'

const E3ContentArea = ({ className, image }) => {
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>E-3 Visa</h1>
            <p>An <a href='https://onlinevisas.com/e3-visa/'>E-3 visa</a> (“Australian Specialty Occupation Professional”) is for an Australian national coming to the U.S. to perform services in a professional field.</p>

            <p>The E-3 may also be issued to an E-3 holder's spouse and/or children under 21 years.</p>

            <h2>Key Considerations for E-3 Visa</h2>
            <p>The E-3 Visa is specifically for Australian professionals. That means the job requires a university degree and the applicant has a relevant university degree.</p>

            <p>The E-3 is a great visa. It is good in two-year increments and allows the spouse of the E-3 holder to get an Employment Authorization Document (EAD) to work in whatever profession they wish.</p>

            <h3>E-3 Visa Requirements</h3>
            <p>There are a couple of important elements that go into the strategy of building an E-3 visa application.</p>
            <p>First there must be an LCA. That is a Labor Condition Application that goes to the US Department of Labor to determine whether the job in question meets the Department's standards. Turnaround is usually 7-10 days.</p>
            <p>The second important requirement is that the job must pay the <i>prevailing wage</i> for the County or State in which the job will be based.</p>

            <h3>Specific Requirements</h3>
            <ol>
                <li>be a national of Australia</li>
                <li>have legitimate U.S. employment offer</li>
                <li>possess necessary academic or other qualifying credentials.</li>
                <li>fill a position that qualifies as a “specialty occupation.” This typically means:
                    <ol>
                        <li>Application of a body of highly specialized knowledge and</li>
                        <li>Attainment of university degree or its equivalent in that specialty</li>
                    </ol>
                </li>
            </ol>

            <h3>Applying for Your E-3 Visa</h3>
            <p>Provided the requirements are met, there are a couple of ways you can proceed:</p>
            <ol>
                <li>You can send your application to a US Consulate in Australia which normally has a pretty quick turnaround.</li>
                <li>Or you can send it to USCIS in the United States. When approved, if the Australian national is in the US on another visa, they may then transfer onto the E-3 visa or otherwise may return to Australia.</li>
            </ol>

            <h3>E-3 Visa Additional Terms</h3>
            <p><strong>Family members </strong>- includes spouses or children under 21 years. Nationality need not be the same. Spouses may be approved for work but not children (when under E-3 status).</p>

            <p><strong>Period of stay </strong>- Up to two years per extension with no maximum number of extensions (some exceptions)</p>

            <p><strong>Annual maximum </strong>- a maximum of 10500 are issued annually. Family members do not count against this limitation.</p>

            <h3>E-3D Dependents Visa</h3>
            <p>In order to qualify for an E-3D visa, you must demonstrate to the U.S. consular officer that the relationship exists. Usually, your application can be accomplished with a marriage certificate (for spouses) or a birth certificate (for dependent children).</p>

            <p>Note that the United States does not recognize <i>de facto</i> relationships, so to qualify as a spouse, you will need to provide a valid marriage certificate. You must also show that the principal applicant is the recipient of an E-3 visa.</p>
            </div>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1Cu9gC1YSAgbQWo3QGP6nkiTTnbY7oTFHbnEnBwGKROjp3WHYMO1RdaGWojnvIVJV"></div>
    </div>
    );
};
export default E3ContentArea