import React, { useEffect } from 'react'

import './style.scss'

const B1ContentArea = ({ className }) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentWrap">
            <h2>B-1 Visa & B-2 Visa</h2>
            <p>The B-1 and B-2 visas are multi-use visas for people who are entering the United States, usually for up to six months, for a range of purposes:</p>
            <ol>
                <li>to travel</li>
                <li>visit friends</li>
                <li>conventions</li>
                <li>go to conferences</li>
                <li>compete in certain athletic competitions (including for prize money)</li>
            </ol>
            
            <h2>Key Considerations for B-1 & B-2 Visas</h2>
            <p>The important strategies for a <a href='https://onlinevisas.com/b1-visa/'>B-1 visa</a> or <a href='https://onlinevisas.com/b1-visa/'>B-2 visa</a> application revolve around how to develop these documents for the Consulate.</p>
            <p><strong>Their primary concern is whether the applicant is going to go back to their home country or intends to stay in the US.</strong></p>
            <p>So the key to your application will be to prove that there are ties to your home country which may include:</p>
            <ol>
                <li>real estate</li>
                <li>a job to go back to</li>
                <li>dependent family and friends</li>
            </ol>
            <p>B-1 and B-2 visas are highly discretionary. Without sufficient evidence showing reasons why you will return they can be denied.</p>
            
            <h2>B-1 Visa</h2>
            <p>The B-1 visa is for visitors to the U.S. for business purposes including:</p>
            <ol>
                <li>consulting with clients or business associates</li>
                <li>conferences or seminars</li>
                <li>conventions</li>
                <li>research</li>
                <li>legal negotiations or proceedings</li>
            </ol>

            <h3>B-1 Visa Eligibility</h3>
            <ol>
                <li><strong>Must show “Non-immigrant intent”</strong> - i.e. that you intend to depart the U.S. after completion of their activities or intend to maintain foreign residence.</li>
                <li><strong>Valid for up to six months</strong> - and can be renewed for another six months.</li>
                <li><strong>Most B-1 visa applications require an interview</strong> - at local U.S. embassy</li>
            </ol>

            <h3>Permitted B-1 Activities</h3>
            <ol>
                <li>Carrying out commercial transactions that do not involve U.S. employment</li>
                <li>Negotiating transactions</li>
                <li>Litigation or legal negotiations</li>
                <li>Lecturers or speakers to U.S. campuses</li>
                <li>Professional conferences, seminars, conventions</li>
                <li>Independent research, feasibility studies, market research</li>
                <li>Athletic performance for prize money but not salary</li>
                <li>Personal assistant or domestic servants traveling with employer who is on B, E, F, H, I, J, L, M, O, P, R, or TN visa</li>
                <li>Technical personnel to install or service equipment</li>
                <li>Excludes: visitors here for purposes of work, study, foreign press & media</li>
            </ol>

            <h2>B-1 Family & Dependents</h2>
            <ol>
                <li>Dependents (spouse, children) can accompany a B-1 visa holder and are considered B-2 visa holders.</li>
                <li>Dependents are NOT allowed to be gainfully employed under B-2 status (but may qualify under another visa type). There is an exception. Arrange a free consultation with your attorney about “B-1 in lieu of H-1B.”</li>
            </ol>

            <h2>B-2 Tourist/Pleasure Visa</h2>
            <p>The B-2 visa is for individuals visiting the U.S. temporarily for pleasure or to visit family or friends.</p>

            <h3>B-2 Visa Requirements</h3>
            <ol>
                <li>Visit will be temporary</li>
                <li>Will depart U.S. at end of your authorized stay or any extension</li>
                <li>In possession of valid passport</li>
                <li>Maintain foreign residence you have no intention of abandoning</li>
                <li>Ability to support yourself financially while in the U.S.</li>
            </ol>

            <h3>B-2 Visa Used By</h3>
            <ol>
                <li>Tourists on a pleasure trip to the U.S.</li>
                <li>Visit friends and relatives in the U.S.</li>
                <li>Foreign nationals coming to U.S. to marry U.S. citizen or Green Card holder (must depart U.S. to ultimately immigrate)</li>
                <li>Amateur athletes, musicians, etc., participating in their respective fields</li>
                <li>Conventions of social (vs. business) organizations</li>
                <li>Foreign nationals coming to U.S. for medical treatment</li>
            </ol>            
            </div>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1yNqozT35ZJHNGtKsfnaTZuGKuaH38CD3lDhGkShURai5h6Ymmu6UtWkUn74IlAgH"></div>
            </div>
    );
};

export default B1ContentArea