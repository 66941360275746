import React, { useEffect } from 'react'

import './style.scss'

const EB5ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h1>EB-5 Visa</h1>
                <strong>About the EB-5 — “Immigrant Investor Green Card”.</strong>

                <p>The EB-5 Immigrant Investor Permanent Residence/Green Card offers permanent
                residency in the U.S. for investors who infuse a minimum of $800,000 for projects in
                Targeted Employment Areas (TEAs) or for infrastructure projects and a minimum of
                $1,050,000 for projects outside of TEAs. This investment must result in 10 or more
                full-time jobs for US or legal permanent resident employees.</p>
                
                <h2>Key Considerations for EB-5 Visa</h2>

                <p>An <strong>EB-5 Investor Visa</strong> provides some great benefits:</p>
                
                <ol>
                    <li>Typically faster Green Card than other methods for petitioner and family.</li>
                    <li>No requirement of a job offer or labor certification.</li>
                    <li>For accredited investors:
                        <ul>
                            <li>Net worth over $1 million, excluding primary residence.</li>
                            <li>Or earned income that exceeded $200,000 (or $300,000 together with a spouse or spousal equivalent) in each of the prior two years, and reasonably expects the same for the current year.</li>
                        </ul>
                    </li>               
                    <li>The opportunity to be passively or actively invested depending on the project.</li> 
                </ol>

                <p>There has been substantial improvement in the consistency of the EB-5 visa, resulting in more and more confidence in the program by investors.</p>

                <h2>EB-5 Eligibility Criteria</h2>
                
                <h3>Regional Centers</h3>
                
                <ol>
                    <li>Private projects with pre-determined investment
                    opportunities requiring investor to infuse capital to
                    participate.</li>
                    <li>Most regional centers offer limited partnership
                    structure.</li>
                    <li>Your investment is comingled with other investors.</li>
                    <li>Some allow pay-out plans.</li>
                    <li>Interest rate can vary.</li>
                    <li>Repayment terms can vary.</li>
                    <li>Admin fees can vary.</li>
                </ol>
                
                <h2>Stand Alone</h2>

                <ol>
                    <li>Project can only have one EB-5 investor</li>
                    <li>Other investors are permitted</li>
                    <li>Can be in either $800,000 (TEA) or $1,050,000 areas</li>
                    <li>We can assist with Matter of Ho business plans,
                        corporate structure and set-up, transactional
                        document contracts drafting</li>
                </ol>
                
                <h3>Velie Global Law's Business Model and Services</h3>
                
                <p>Velie Global Law's business model is to
                work alongside licensed broker dealers to
                allow clients to review Regional Centers.
                While Velie Global Law lawyers are not
                allowed to introduce projects, licensed
                broker dealers can. We will introduce you
                to a licensed broker dealer to see what
                regional center makes sense for you.</p>

                <p>Broker Dealers can help you understand
                the histories, backgrounds, and risks
                associated with EB5 regional center
                projects. Broker dealers help negotiate
                payment plans, interest rates, payback
                terms and admin costs with the regional
                cetner.</p>

                <p>Velie Global Law lawyers will help
                understand if and how to meet accredited
                investment standards, issues and
                solutions regarding source of funds, and
                strategies and knowledge and briefing
                your case.</p>  
                
                <h2>Family of EB-5 Visa Holders</h2>                    
                
                <p>The spouse and unmarried children under 21 years may be admitted to the U.S. on a 2-year conditional period. You may then file a petition (I-829) to remove conditions from family member Green Cards along with the
                investor.</p>
                
                <p>As lawful permanent residents (Green Card holders), your family members will be authorized to work or attend school in the U.S.</p>
                <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1FQzVTHKLbTtwlG9ARnp9GdJKCQObqpVRrFsUHltKxjcHDxruqp1sLw5ofnbhLlzJ"></div>
            </div>
        </div>
    )
}
export default EB5ContentArea