import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import ContactArea from '../../../components/ContactArea'
import Testmonial from '../../../components/Testmonial'

// images
import breadcumb from '../../../images/breadcumb/work-visa.jpg'
import team from '../../../images/expert/yesh-10.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Attorneys', route: '/attorneys' },
    { name: 'Yesh Vasamsetty' }
]

const teamDetails = {
    name: 'Yesh Vasamsetty',
    position: 'Attorney',
    practiceArea: 'Tax Law, Immigration, RFE Specialist',
    experience: '3 years',
    office: 'Plano, Texas',
    phone: '(405) 310-4333',
    email: 'information@velielaw.com',
    memberships: [
        'Jersey Bar Association',
        'Texas Bar Association',
        'American Immigration Lawyers Association (AILA)'
    ],
    communityService: [
        'Volunteer Legal Services with local immigration non-profits',
        'Pro bono representation for VAWA and U visa applicants',
        'Educational workshops on tax compliance for small businesses and immigrant communities'
    ],
    education: [
        'Juris Doctor, Florida Coastal School of Law',
        'Bachelor of Science in Biology, Thompson Rivers University'
    ]
};

const SingleTeamPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={`Meet ${teamDetails.name}`}
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={team} alt={teamDetails.name} />
                                        </div>
                                        <h4>{teamDetails.name}</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Important Information</h4>
                                        <ul className="teamMembarInfo">
                                            <li><span>Position: </span>{teamDetails.position}</li>
                                            <li><span>Practice Area: </span>{teamDetails.practiceArea}</li>
                                            <li><span>Experience: </span>{teamDetails.experience}</li>
                                            <li><span>Office: </span>{teamDetails.office}</li>
                                            <li><span>Phone: </span>{teamDetails.phone}</li>
                                            <li><span>Email: </span>{teamDetails.email}</li>
                                        </ul>
                                        <ul className="socialShare">
                                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h3>Personal Experience</h3>
                                        <p>Employment-based immigration: H-1B, L-1, O-1, EB-2/EB-3 visas, Humanitarian immigration: U visas, VAWA cases, Tax law: IRS and State tax debt resolution, audits, appeals, and collection alternatives, Strategic counsel for multinational corporations and individual professionals on complex immigration matters. Speaker at industry conferences on immigration law and tax compliance, Author of articles on navigating the U.S. immigration system and tax planning for immigrants, Recognition by peers and clients for outstanding service in both immigration and tax law.</p>                                        
                                        <h5>Memberships</h5>
                                        <ul>
                                            {teamDetails.memberships.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Community Service</h5>
                                        <ul>
                                            {teamDetails.communityService.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Education</h5>
                                        <ul>
                                            {teamDetails.education.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                        
                                        <h5>Language</h5>
                                        <span>English (fluent)</span><br></br>
                                        <span>Telegu (fluent)</span><br></br>
                                        <span>Hindi (fluent)</span><br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactArea className="contactArea" />
            <Testmonial className="testmonialArea" />
            <FooterArea />
        </Fragment>
    )
}
export default SingleTeamPage
