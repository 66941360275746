import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const CategoryMenu = [
    { name: 'Investors', route: "/work-visa" },
    { name: 'Multinational', route: "/work-visa" },
    { name: 'Sports', route: "/work-visa" },
    { name: 'Entertainment', route: "/work-visa" },
    { name: 'Healthcare', route: "/work-visa" },
    { name: 'Higher Education', route: "/work-visa" },
    { name: 'Tech', route: "/work-visa" },
]

const CategorySidebar = ({ className, title }) => {
    return (
        <div className={className}>
            <h3 className="sidebarTitle">{title}</h3>
            <ul>
                {CategoryMenu.map((Category,i) => (
                    <li key={i}><Link to={Category.route}>{Category.name}</Link></li>
                ))}
            </ul>
        </div>
    )
}
export default CategorySidebar