import React, { Fragment, useEffect } from 'react';
import HeaderBotton from '../../../../components/HeaderBottom';
import Breadcumb from '../../../../components/Breadcumb';
import CategorySidebar from '../../../../components/CategorySidebar';
import FooterArea from '../../../../components/FooterArea';
import BannerSidebar from '../../../../components/BannerSidebar';
import FPContentArea from '../../../../components/FPContentArea';
import RecentPosts from '../../../../components/RecentPosts';
import CounterArea from '../../../../components/CounterArea';
import TeamMember from '../../../../components/TeamMember';
import Portfolio from '../../../../components/Portfolio';
import PortfolioItems from '../../../../data/PortfolioItems';
import ImmigrationServices from '../../../../components/ImmigrationServices';
import FeaturedOn from '../../../../components/Featured';
import Testmonial from "../../../../components/Testmonial";
import './style.scss';

// images
import breadcumb from '../../../../images/breadcumb/family-preference-greencard.jpg';
import banner from '../../../../images/practice/handshake.jpg';

const servicesData = [
    {
      title: 'Temporary Visas',
      items: [
        "B-1 Business Visitor",
        "E-1 Treaty Trader",
        "F-1 Academic Student",
        "H-1B Specialty Occupation",
        "H-2A Agricultural Worker",
        "I Media Representative",
        "J-1 Exchange Visitor",
        "L-1 Intracompany Transferee",
        "O-1 Extraordinary Ability",
        "P-1 Athlete or Entertainer",
    ]    
    },
    {
      title: 'Permanent Residency',
      items: [
        "Investor Green Card",
        "Marriage Green Card",
        "Employment-Based Green Card",
        "Diversity Lottery Green Card",
        "Special Immigrant Green Card",
        "Refugee or Asylee Status Green Card"
    ]
    },
    {
      title: 'U.S. Citizenship',
      items: [
        "Citizenship through Naturalization",
        "Citizenship through Parents",
        "Dual Citizenship",
        "Certificate of Citizenship",
        "Citizenship Test and Interview",
        "Oath of Allegiance Ceremony"
    ]
    }
  ];
  
const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Family Visa', route: '/family-visa' },
    { name: 'Family Preference' }
]

const FPPage = () => {
    
useEffect(() => {
const script = document.createElement('script');
script.src = 'https://assets.calendly.com/assets/external/widget.js';
script.async = true;
document.body.appendChild(script);
return () => {
    document.body.removeChild(script);
};
}, []);

    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Family Preference Green Cards"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className="singleArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <aside className="pr25">
                                <div 
                                    className="calendly-inline-widget" 
                                    data-url="https://calendly.com/onlinevisas/strategy-session-by-velie-global-law-firm-199" 
                                    style={{ minWidth: '320px', height: '1000px' }}
                                >                                    
                                </div>
                                <BannerSidebar
                                    image={banner}
                                    className="bannerWrapper"
                                />
                                <CategorySidebar
                                    title="Immigration Areas"
                                    className="CategoryWrap"
                                />
                                <RecentPosts
                                    className="recentPostWrapper"
                                />
                            </aside>
                        </div>
                        <div className="col-lg-8">
                        <FPContentArea
                                className="singleContentArea"
                                
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Testmonial
                className="testmonialArea pt100"
            />
            <FeaturedOn 
                className="featured-on-container"
            />
            <ImmigrationServices 
                className="immigrationServiceArea"
                services={servicesData}
            />
            <CounterArea
                className="counterArea"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
                extraClass="contactTeamAreaBackground contactTeamContent"
            />
            <Portfolio
                className="portfolioArea"
                title="From Visa to Green Card"
                subTitle="Our Work"
                items={PortfolioItems.slice(5, 20)}
            />
            <FooterArea />
        </Fragment>
    )
}
export default FPPage