import React, { useEffect } from 'react'

import './style.scss'

const J1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>J-1 Visa</h1>
            <p>The J-1 Exchange Visitor visa exists to promote the interchange of knowledge, training, and skills in education, arts, and sciences.</p> 
                
            <p>The J-1 visa is a flexible visa and is also relatively easy to get.
            In a similar way to student visa applications, a J visa issuing company will issue the form that is then taken to the US Consulate.</p>
                
            <h2>J-1 Visa Participants</h2>
            <p>Participants can be from various backgrounds, including:</p>
            <ol>
                <li>Foreign medical graduates or physicians pursuing accredited medical education or training.</li>
                <li>Foreign students in exchange programs promoting knowledge in education, arts, or sciences.</li>
                <li>Research scholars to teach, conduct research, or observe.</li>
                <li>Professors to teach, lecture, perform research, or consult at educational institutions, museums, libraries.</li>
                <li>Au pairs that will live with American host families.</li>
                <li>Foreign nationals attending business & industry training programs.</li>
                <li>Research assistants sponsored by the National Institute of Health (NIH).</li>
                <li>Camp counselors in U.S. summer camps.</li>
                <li>Foreign nationals obtaining further academic qualifications or practical training in a specific area of knowledge.</li>
                <li>Potential leaders or experts.</li>
            </ol>
                
            <h2>J-1 Visa Requirements & Terms</h2>
            <ol>
                <li><strong>Travel - </strong> may travel in and out of the U.S. , or within the U.S. , as long as J visa until the program is complete</li>
                <li><strong>Employment - </strong> you may be able to work if the employment is considered academic training related to the course of study, if it is related to academic funding — on-campus work or an economic necessity.</li>
                <li><strong>length of stay</strong> generally a J-1 is issued for the duration of the program. The maximum differs depending on the work category (for example, professors & research scholars have a 3-year maximum, while a camp counselor is allowed 3 months). Most are 1-3 years.</li>
            </ol>
                
            <h2>J-1 Waiver & the Two-Year Foreign Residency Requirement</h2>
            <p>Some J-1 visas are subject to 212(g) and the recipient must return to his/her home country for a minimum of 2-years before returning to the U.S. on another visa.</p>
                
            <p>Other J-1 visas are not subject to the two year home residency requirement.</p>

            <p>The requirements can be either country or industry specific and are listed in the “Skills List” published by the US Department of State in the Federal Register, as contained in this link. <a href='http://www.gpo.gov/fdsys/pkg/FR-2009-04-30/pdf/E9-9657.pdf' alt='Skill list PDF'>Download Skill List PDF</a></p>

            <p>In certain situations, this 2-year requirement may be waived. Waivers of J-1 visas may be very complex and require the knowledge of an experienced Immigration law firm. We will be happy to advise you.</p>

            <h2>J-1 Visa Spouses & Children (J2 Visa)</h2>

            <p>Spouses and unmarried minor children may apply do so, and would hold the J-2 visa. J-2 visa holders are subject to the following:</p>

            <ol>
                <li>May travel in & out, or live continuously in the U.S.</li>
                <li>Study in the U.S.</li>
                <li>Work in the U.S. with proper authorization (may apply for work permit after arrival).</li>
                <li>J-2 status is dependent on the primary J-1 visa hold's status.</li>
            </ol>
  
            <h2>J-1 Visa Sponsors - Becoming a Designated Program</h2>
            <p>J-1 visa program compliance can be complex. Our firm has extensive experience assisting in establishing internal controls necessary for initial approval and ongoing compliance.</p>   

            <p>The general criteria a sponsor of a J-1 program must satisfy include the following:</p>
            <ol>
                <li>Must be a bona fide educational and cultural exchange program.</li>
                <li>Detail the selection, placement, orientation, evaluation, and supervision of exchange visitors.</li>
                <li>Provide at least 5 exchange visitors per year.</li>
                <li>Provide cross-cultural activities for visitors.</li>
                <li>Bona fide effort to create “reciprocity” - the participation of U.S. citizens in similar programs.</li>
                <li>Sponsor must have U.S. citizenship.</li>
                <li>Demonstrate financial ability to maintain the program.</li>
                <li>Provide insurance coverage.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/32RdqR8im04kc9keVkrq8Liu73YVR9ZGdZ4QKY73pDf5P4LxXJjBUYex0Y6Z0W5Gz"></div>
            </div>
    </div>
    )
}
export default J1ContentArea