import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import './style.scss'

const FamilyContentArea = ({ className, image }) => {
  useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://webforms.pipedrive.com/f/loader";
      script.async = true;
      document.body.appendChild(script);

      return () => {
          document.body.removeChild(script);
      }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h1>Family Visa U.S.</h1>
                <p>Need a family visa USA application? Velie Law can help with two main categories of family-based US visa applications.</p>

                <h2>Temporary & Non-Immigrant Family Visas (U.S.)</h2>
                <nav className='visaLinks'>
                  <ul>
                    <li><Link to="/k1-visa"><strong>K-1 Visa</strong></Link> - Fiancé visa seeking to enter the U.S. to get married</li>
                    <li><Link to="/k2-visa"><strong>K-2 Visa</strong></Link> - Children of K-1 visa holder</li>
                    <li><Link to="/k3-visa"><strong>K-3 Visa</strong></Link> - Spouse of U.S. citizen awaiting an immigrant (permanent) visa</li>
                    <li><Link to="/k4-visa"><strong>K-4 Visa</strong></Link> - Children of K-3 visa holder</li>
                  </ul>
                </nav>
                <h2>Permanent Residency Family Visas (U.S.)</h2>
                <nav className='visaLinks'>
                  <ul>
                    <li><Link to="/spouse-visa"><strong>Green Cards for Immediate Family Members</strong></Link> of U.S. Citizens and Permanent Residents</li>
                    <li><Link to="/family-preference"><strong>Family Preference Green Cards</strong></Link> — for non-immediate family members</li>
                  </ul>
                </nav>
                <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1C8ARgOWOSpIhA3QA56RwD79WED3o14qwAQgUHHNvhi93x1JBmNwMjRhFzLztGjZ1"></div>
            </div>
        </div>
    )
}
export default FamilyContentArea