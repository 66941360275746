import React, { useEffect } from 'react'

import './style.scss'

const IContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>I Visa</h1>
            <p>The I Visa is for foreign (non-US) media personnel (editors, reporters, film crews, freelance journalists) who are under contract to a foreign media source that is doing business in the United States.</p>
                
            <p>The I Visa term is one year but it can be extended for as long as the project is ongoing.</p>
                
            <h2>I/I-1 Visa Requirements</h2>
            <ul>
              <li>Allowed occupations: reporters, film crews, editors, independent production companies, freelance journalists working under contract or those in similar positions.</li>
              <li>Commercial media disallowed: the I-1 visa may not be used for commercial entertainment or advertising purposes.</li>
              <li>Length of stay - initial maximum duration is 1 year but dependent on the length of time it will take to complete the job or cover the event. You may file indefinite extensions if the task warrants. There are instances where you may travel within the U.S. on a visitor's visa.</li>
            </ul>
                
            <h2>I Visa Family Members</h2>
            <p>Spouses and/or minor children may also apply for an I-1 visa following the approval of the primary I visa.</p>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1C9W4kn0AzwQpo1r4o8Vet1RavMLlMKBmo0ZrQHPOH2Q0hY00rbcxXhhxq2be88U3"></div>
        </div>
    </div>
    )
}
export default IContentArea