import React from 'react'
import PipeDriveForm from '../PipeDriveForm'
import './style.scss'

const ContactArea = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="contactInfo">
                            <span>Difference Between a Strategy Session and a Consultation</span>
                            <br></br>
                            <h2>Strategy Session</h2>
                            <h6>Call us at (405) 310-4333 or fill out the form.</h6>
                            <p>Unlike typical consultations, our Strategy Sessions, <br></br>leverage relationships, strategy, knowledge, technology, <br></br> maximize your chances of obtaining a visa.</p>
                            <p>During your Strategy Session we'll answer these questions:</p>
                            <h3>Corporate Strategy</h3>
                            <ol>
                                <li><strong>Analysis or creation of company's immigration processes and compliance.</strong></li>
                                <li><strong>Visa pathways that may apply to your company.</strong></li>
                                <li><strong>Mininmum standards to obtain visas.</strong></li>                            
                            </ol>            
                            <h3>Case by Case Strategy</h3>
                            <ol>
                                <li><strong>Whether you meet the standards to obtain a visa.</strong></li>
                                <li><strong>How long it will take.</strong></li>
                                <li><strong>How much it will cost.</strong></li>
                            </ol>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <PipeDriveForm />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactArea