import React, { useState, useEffect, useRef } from 'react';
// Removed useSelector import since it's not being used
import Odometer from 'react-odometerjs';
import './style.scss';

const counters = [
    { endValue: 99, title: 'Approval Rating', level: '%' },
    { endValue: 100, title: 'Years of Generational Immigration Practice', level: '+' },
    { endValue: 30, title: 'Years of Experience', level: '+' },
    { endValue: 20000, title: 'Cases', level: '+' },
];

const useVisibility = (ref, threshold = 0.1) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const currentRef = ref.current; // Store current ref for cleanup
        const observer = new IntersectionObserver(
            ([entry]) => setIsVisible(entry.isIntersecting),
            { threshold }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref, threshold]);

    return isVisible;
};

const AnimatedCounter = ({ endValue }) => {
    const [value, setValue] = useState(0);
    const ref = useRef(null);
    const isVisible = useVisibility(ref);

    useEffect(() => {
        let interval;
        if (isVisible && value < endValue) {
            interval = setInterval(() => {
                setValue((prevValue) => Math.min(prevValue + 1, endValue));
            }, 1000 / endValue); // May need to adjust the interval for a smoother animation
        }
        return () => clearInterval(interval);
    }, [isVisible, value, endValue]);

    return (
        <div ref={ref}>
            <Odometer value={value} />
        </div>
    );
};

const CounterArea = ({ className, fullWidth }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    {/* Column for counters */}
                    <div className={fullWidth ? "col-12" : "col-lg-6 col-12"}>
                        <div className="counterMainWrapper">
                            {counters.map((counter, i) => (
                                <div key={i} className="counterWrap">
                                    <div className="count">
                                        <AnimatedCounter endValue={counter.endValue} />
                                        {counter.level && <span className="level">{counter.level}</span>}
                                    </div>
                                    <p>{counter.title}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Separate column for additional text */}
                    {!fullWidth && (
                        <div className="col-lg-6 col-12">
                            <div className="counterTextWrapper">
                                <h2>Dedicated to Excellence</h2>
                                <p>With a century of combined expertise, we guarantee the best pathway for your immigration journey</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CounterArea;