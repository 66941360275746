import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/HeaderBottom'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial"
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import FooterArea from '../../../components/FooterArea'
import PortfolioItems from '../../../data/PortfolioItems'

// images
import about from '../../../images/about/1.jpg'
import FeaturedOn from '../../../components/Featured'

// Temporarily Disabled
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'

const aboutTextHome = [
    { text: 'One word changes everything as Velie Law Firm\'s domestic, award-winning Immigration boutique transforms into VELIE GLOBAL LAW FIRM.' },
    { text: 'The addition of "global" seamlessly integrates our worldwide attorney network with our America and India-based production offices, forming a unified multinational 24/7 law firm driven by our industry-leading AI-powered Immigration platform, across multiple practice areas.' },
]

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'American Legal Solutions for Global Clients',
        text: 'Velie Global Law Firm',
        button: 'Contact us now',
        titleClass: 'smallTitle',
    },
    {
        images: 'slideWrapperTwo',
        title: 'Business Immigration',
        // subTitle: '',
        text: 'Velie Global Law Firm',
        button: 'Contact us now',
    },
    {
        images: 'slideWrapperThree',
        title: 'Corporate Law',
        // subTitle: '',
        text: 'Velie Global Law Firm',
        button: 'Contact us now',
    },
    {
        images: 'slideWrapperFour',
        title: 'Litigation',
        // subTitle: '',
        text: 'Velie Global Law Firm',
        button: 'Contact us now',        
    },
    {
        images: 'slideWrapperFive',
        title: 'Family Law',
        // subTitle: '',
        text: 'Velie Global Law Firm',
        button: 'Contact us now',
    },
    {
        images: 'slideWrapperSix',
        title: 'Tax Law',
        // subTitle: '',
        text: 'Velie Global Law Firm',
        button: 'Contact us now',
    },
]

const services = [
    {
        icon: 'flaticon-network',
        title: 'Business Immigration',
        content: 'As part of the OnlineVisas network, we have the means to obtain visas, work permits, or residency permits.',
        link: '/work-visa',
    },
    {
        icon: 'flaticon-employee',
        title: 'Corporate Law',
        content: 'Expert legal advice to help navigate complex corporate transactions, mergers, and acquisitions.',
        link: '/corporate-law',
    },
    {
        icon: 'flaticon-wounded',
        title: 'Litigation',
        content: 'Providing strong representation in court for both civil and commercial disputes, ensuring your rights are protected.',
        link: '/litigation'
    },
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'Guidance on legal matters related to family, including divorce, child custody, and alimony.',
        link: '/family-law',
    },
    {
        icon: 'flaticon-thief',
        title: 'Tax Law',
        content: 'Expert advice on tax planning, compliance, and resolving disputes to help you manage and minimize tax liabilities.',
        link: '/tax-law',
    },
    {
        icon: 'flaticon-parents',
        title: 'Temporary & Permanent Residency',
        content: 'Expert guidance for securing U.S. visas, for short-term stays or permanent residency.',
        link: '/family-visa',
    },    
    // {
    //     icon: 'flaticon-scale',
    //     title: 'DACA Application',
    //     content: 'Our service offers guidance on DACA, granting legal status to eligible minors without a direct path to citizenship.'
    // },
    // {
    //     icon: 'flaticon-network',
    //     title: 'Site Investigations',
    //     content: 'Our firm conducts site inspections to ensure your business meets immigration employment laws.'
    // },
]

const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBottom className="headerBottomArea" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea"
            />
            <Service 
                className="serviceArea"
            />
            <About
                className="aboutArea"
                title="About Us"
                images={about}
                paragraphs={aboutTextHome}
                showButton={true}
                showImage={true}
            />
            <ServiceArea
                className="ourServiceArea"
                title="Areas of Practice"
                subTitle="Ways Our Firms Can Serve You"
                services={services}
            />
            <TeamMember
                title="Qualified Attorneys"
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}                
            />
            <Testmonial
                className="testmonialArea"
            />
            <Portfolio
                className="portfolioArea"
                title="Immigration Cases"
                subTitle="Our Work"
                items={PortfolioItems}
            />
            <ContactArea
                className="contactArea"
            />
            <FeaturedOn 
                className="featured-on-container"
            />
            {/* <CounterArea
                className="counterArea"
            /> */}
            {/* <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            /> */}
            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default HomePageOne