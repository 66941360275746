import React, { useEffect } from 'react'

import './style.scss'


const P1ContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
                <h1>P-1 Visa</h1>
                
                <strong>The P-1 (“Athlete or Entertainer”) visa is designed for…</strong>

                <ol>
                    <li>Internationally recognized athletes, athletic teams entering the U.S. to participate in an event of international standing.</li>
                    <li>Entertainer or entertainment group recognized as outstanding, to perform or tour within the U.S.</li>
                    <li>Support personnel of the P-1 visa holder(s).</li>
                </ol>

                <h2>P-1 Visa Benefits and Limitations</h2>
                
                <ol>
                    <li><strong>Payment & prize money</strong> - May enter the U.S. and perform for payment or prize money</li>
                    <li><strong>Study</strong> - P-1 holders may undertake part-time study</li>
                    <li><strong>Support personnel</strong> - may also apply for P-1 visa to accompany principal P-1 visa holder</li>
                    <li><strong>Travel</strong> - May Travel freely inside and outside the U.S. while P-1 visa is valid</li>
                    <li><strong>Dependents</strong> - Spouse and/or unmarried children under 21 years old may accompany or visit under a P-4 visa.</li>
                    <li><strong>Permanent residency / Green Card</strong> - P-1 holders may apply for an Adjustment of Status and seek permanent residency (this does not include support personnel)</li>
                    <li><strong>Dependents & employment</strong> - dependents on P-4 visa may not work.</li>
                    <li><strong>Duration</strong> - P-1 holders are generally admitted based on specific performances or competition schedule. The maximum allowable stay is up to 5 years, with extensions a maximum of 10 years.</li>
                    <li><strong>P-2 Visa</strong> - is a variation of the P1 visa, and is designed for artists and entertainers who are engaged in reciprocal cultural exchange programs.</li>
                </ol>

                <h2>Agent Based Petitions</h2>
                
                <p>The P-1 visa may have multiple direct employers, sponsors and revenues sources if an agent petitions for the case.</p>
                
                <p>The direct employers can be both international and domestic but must execute a contract with the beneficiary.</p>

                <h2>P-1 Visa or O-1 Visa?</h2>
                
                <p>The P-1 visa has some similarities to the O-1 visa, as well as some key differences.</p>
                Eligibility standards are lower for the P-1 visa compared to the O-1.
                
                <p>However, the O-1 allows a wider range of fields, usually allows a longer stay, and does not require the visa holder to maintain residence in their home country.</p>

                <h2>Trust Velie Law With Your P-1, P-2, or P-4 Visa Application
                OnlineVisas is recognized as a leading immigration law firm for athletes.</h2>

                <p>Lead attorney Jon Velie was interviewed by the Washington Post in this article from February 10, 2016, “How refugees fleeing Syria and ISIS are keeping their Olympic hopes alive” where he praises the support the IOC gives to athletes in troubled countries.</p>
                
                <blockquote>“The Olympics bring out the best in nations, even when they may be at their worst,” he said. “These refugee athletes will provide hope for their embattled brethren and give them a stage to show their spirit of perseverance through sport while their homes and life may be in ruin.”</blockquote>
                <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2Z9KvlDs9W2mW4mpgXljFSyQVbWdvl0gDZXxyWnXtKbMuvlxehX31lBU3q8AlBy9l"></div>
            </div>
        </div>
    )
}
export default P1ContentArea