import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import './style.scss'

const FPContentArea = ({ className, image }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://webforms.pipedrive.com/f/loader";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className={className}>
            <div className="singleContentImg">
                <img src={image} alt="" />
            </div>
            <div className="singleContentWrap">
            <h1>Family Preference Green Cards</h1>
            <p>Family Preference Green Cards give permanent residency in the United States to non-immediate family members.</p>

            <p>Immediate relatives of U.S. Citizens — i.e., spouses, parents, and unmarried children under 21 — should apply for Green Cards for Immediate Relatives (<Link to='/contact'>contact</Link> us for details).</p>

            <h2>Key Considerations for Family Preference Green Cards</h2>
            <p>These <i>family preference</i> visa types are for specific, more distant family relationships with a U.S. citizen and some specified relationships with a Lawful Permanent Resident (LPR).</p>
            <p>While these are relatively straightforward green cards to obtain, please note that there can be significant waiting times involved. See below for details.</p>
            <p>Note: Numbers are strictly controlled</p>
            <p>Because a limited number of each visa type is made available each fiscal year, it may result in long waiting times (details below).</p>

            <h2>Details of Family Preference Types</h2>
            <ol>
                <li><strong>F-1</strong>: The Family First Preference F-1 is for unmarried sons and daughters of U.S. citizens (and their minor children if any).</li>
                <li><strong>F-2</strong>: Spouses, minor children, and older unmarried sons and daughters:
                    <ol>
                        <li><strong>F-2A</strong>: For spouses and children (under 21 years of age) of permanent residents.</li>
                        <li><strong>F-2B</strong>: Unmarried sons and daughters (21 years of age or older) of permanent residents.</li>
                    </ol>
                </li>
                <li><strong>F-3</strong>: Married sons and daughters of U.S. citizens and their spouses and minor children.</li>
                <li><strong>F-4</strong>: Brothers and sisters of U.S. citizens and their spouses and minor children.</li>
            </ol>

            <h2>Family Preference Green Card Allocations</h2>
            <p>The Visa Bulletin published on the fifteenth day of each calendar month gives an indication of current waiting times for the various preference types.</p>
            <p>Your application may become due for issuance only if its priority date is before the relevant date listed on the bulletin.</p>
            <p>Four countries (China, India, Mexico, and Philippines) have their own dates so please refer to the bulletin for current information.</p>

            <h2>Numbers of Visas Allocated Annually Per Preference</h2>
            <ol>
                <li>First: (F-1) Unmarried Sons and Daughters of U.S. Citizens: 23,400 plus any numbers not required for fourth preference.</li>
                <li>Second: Spouses and Children, and Unmarried Sons and Daughters of Permanent Residents: 114,200 plus the number (if any) by which the worldwide family preference level exceeds 226,000, plus any unused first preference numbers.</li>
                <li>Third: (F-3) Married Sons and Daughters of U.S. Citizens: 23,400 plus any numbers not required by first and second preferences.</li>
                <li>Fourth: (F-4) Brothers and Sisters of Adult U.S. Citizens: 65,000 plus any numbers not required by first three preferences.</li>
            </ol>
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2XiKB2M0Y306VQsCiuEwIlpwIKGcfrABRxkx3dWo8iRMItbGMFJCGzvT5em8Z14gX"></div>
            </div>
    </div>
    )
}
export default FPContentArea