import React from 'react';
import SectionTitle from '../Title';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';

const Portfolio = ({ title, subTitle, className, items }) => {
  
  const settings = {
    dots: false,
    infinite: true,
    pauseOnHover: true,
    speed: 35000,
    slidesToShow: 5,
    slidesToScroll: 25,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 1024, // for devices with width less than 1024px
        settings: {
          slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600, // for devices with width less than 600px
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
              dots: false
            }
      },
      {
          breakpoint: 480, // for devices with width less than 480px
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };

    return (
      <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle 
              title={title} 
              subTitle={subTitle}
            />
          </div>
        </div>
      </div>
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.id} className="portfolioItem">
            <img src={item.images} alt="A Carousel of Petition Covers" />
            <div className="portfolioContent">
              <p>{item.subtitle}</p>
              <h3>{item.title}</h3>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default Portfolio